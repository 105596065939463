<template>
    <div class="basic">
        <div style="padding: 20px 0; border-bottom: 1px solid #eeeeee" v-for="item in contractList">
            <div class="content_list">
                <p>
                    <span class="title">合同编号</span>
                    <span class="cont">{{ item.contractNo ? item.contractNo : '- -' }}</span>
                </p>
                <p>
                    <span class="title">甲方</span>
                    <span class="cont">{{ item.contractA ? item.contractA : '- -' }}</span>
                </p>
                <p>
                    <span class="title">打款人</span>
                    <span class="cont">{{ item.contractA ? item.contractA : '- -' }}</span>
                </p>
            </div>

            <div class="content_list">
                <p>
                    <span class="title">合同金额</span>
                    <span class="cont">{{
                        item.contractAmount ? item.contractAmount : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">乙方</span>
                    <span class="cont" v-if="item.typeB != 9">
                        {{ $tableDataHandle.typeB(item.typeB) }}
                    </span>
                    <span class="cont" v-if="item.typeB == 9">{{ item.contractB }}</span>
                </p>
                <p>
                    <span class="title">明细</span>
                    <span class="cont">{{ item.remark ? item.remark : '- -' }}</span>
                </p>
            </div>
            <div class="content_list yyzz">
                <p>
                    <span class="title" style="display: inline-block">营业执照</span>
                    <!--  -->
                    <span class="cont" v-if="item.businessLicenseUrl">
                        <!-- <el-image
                            style="width: 100px; height: 100px"
                            src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.2008php.com%2F09_Website_appreciate%2F10-07-11%2F1278861720_g.jpg&refer=http%3A%2F%2Fwww.2008php.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653637854&t=c7f20fe4924ebd202c69fca6c64dccd8"
                            :preview-src-list="[
                                'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.2008php.com%2F09_Website_appreciate%2F10-07-11%2F1278861720_g.jpg&refer=http%3A%2F%2Fwww.2008php.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653637854&t=c7f20fe4924ebd202c69fca6c64dccd8',
                            ]"
                        >
                        </el-image> -->
                        <el-image
                            v-if="
                                item.businessLicenseUrl.indexOf('.jpg') != -1 ||
                                item.businessLicenseUrl.indexOf('.png') != -1 ||
                                item.businessLicenseUrl.indexOf('.gif') != -1
                            "
                            style="width: 100px; height: 100px"
                            :src="item.businessLicenseUrl"
                            :preview-src-list="[item.businessLicenseUrl]"
                        >
                        </el-image>
                        <span
                            class="pdf"
                            v-if="
                                item.businessLicenseUrl.indexOf('.pdf') != -1 ||
                                item.businessLicenseUrl.indexOf('.PDF') != -1
                            "
                            @click="onPreview(item.businessLicenseUrl)"
                            style="cursor: pointer"
                        >
                            <img src="@/img/pdfUrl.png" alt=""
                        /></span>
                        <span
                            class="word"
                            v-if="item.businessLicenseUrl.indexOf('.doc') != -1"
                            @click="onPreview(item.businessLicenseUrl)"
                            style="cursor: pointer"
                        >
                            <img src="@/img/word.png" alt="" />
                        </span>
                    </span>
                </p>
                <p>
                    <span class="title" style="display: inline-block">附件</span>
                    <span
                        style="margin-right: 8px"
                        class="cont"
                        v-show="item.contractAttachmentBOList"
                        v-for="items in item.contractAttachmentBOList"
                    >
                        <el-image
                            v-if="
                                items.attachmentUrl &&
                                (items.attachmentUrl.indexOf('.jpg') != -1 ||
                                    items.attachmentUrl.indexOf('.png') != -1 ||
                                    items.attachmentUrl.indexOf('.gif') != -1)
                            "
                            style="width: 100px; height: 100px"
                            :src="items.attachmentUrl"
                            :preview-src-list="[items.attachmentUrl]"
                        >
                        </el-image>
                        <span
                            class="pdf"
                            v-if="
                                items.attachmentUrl &&
                                (items.attachmentUrl.indexOf('.pdf') != -1 ||
                                    items.attachmentUrl.indexOf('.PDF') != -1)
                            "
                            @click="onPreview(items.attachmentUrl)"
                            style="cursor: pointer"
                        >
                            <img src="@/img/pdfUrl.png" alt="" />
                        </span>
                        <span
                            class="word"
                            v-if="items.attachmentUrl && items.attachmentUrl.indexOf('.doc') != -1"
                            @click="onPreview(items.attachmentUrl)"
                            style="cursor: pointer"
                        >
                            <img src="@/img/word.png" alt="" />
                        </span>
                    </span>
                </p>
                <p></p>
            </div>
        </div>
    </div>
</template>
<script>
import { customerId } from '@/api/user/ae/customer';
export default {
    data() {
        return {
            contractList: [{}],
            businessLicenseUrl: '',
            attachmentUrl: '',
            searchUserCustomer: Boolean(sessionStorage.getItem('searchUserCustomer')) || false,
            depSearchCustomer: Boolean(sessionStorage.getItem('depSearchCustomer')) || false,
        };
    },
    methods: {
        getData(obj, btnP) {
            let data = {
                param: {
                    customerId: obj.id,
                    statusList: [7, 8, 10, 11, 12],
                },
                pageSize: 0,
                pageNum: 0,
            };
            if (this.searchUserCustomer && !this.depSearchCustomer) {
                data.param.departmentId = sessionStorage.getItem('departmentId');
            } else {
                if (!this.depSearchCustomer) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            customerId(data).then((res) => {
                console.log(res);
                this.contractList = res.data.list;
            });
        },
        onPreview(url) {
            if (url.indexOf('.doc') != -1 || url.indexOf('.docx') != -1) {
                window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url);
            } else {
                window.open(url, '_blank');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.basic {
    width: 100%;
    .content_list {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 7px 24px;
        font-size: 13px;
        p {
            width: 33%;
            display: flex;
        }
        span {
            display: inline-block;
        }
        .title {
            min-width: 65px;
            margin-right: 27px;
            color: #666666;
        }
        .cont {
            color: #333333;
            font-weight: 600;
        }
    }
    .yyzz {
    }
}
</style>
