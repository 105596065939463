<template>
    <div class="basic">
        <div>
            <p style="position: relative">
                <span class="title">客户名称</span>
                <span class="name_cont">
                    <el-input
                        v-model="form.companyName"
                        size="mini"
                        style="max-width: 200px; padding: 0"
                        placeholder="请输入内容"
                        :disabled="disabled"
                        @input="inputCompantName"
                    ></el-input>
                    <i
                        class="el-icon-edit-outline"
                        @click="onUpdateComp"
                        v-show="
                            disabled &&
                            (btnP.edit ||
                                originalCustomer.adminId == adminId) &&
                            data.aiChaStatus != 1 &&
                            originalCustomer.status == 1
                        "
                    ></i>
                    <!-- &&
                            btnP.edit &&
                            data.followAdminId == adminId -->
                    <el-button
                        @click="onSubmit(data)"
                        size="mini"
                        type="text"
                        v-show="!disabled"
                        style="color: #2162cb"
                        >保存</el-button
                    >
                    <el-button
                        @click="onquxiao()"
                        size="mini"
                        type="text"
                        v-show="!disabled"
                        style="color: #d0021b"
                        >取消</el-button
                    >
                </span>
                <!-- <em
                    class="repeat"
                    v-show="
                        tag === false && form.companyName != data.companyName
                    "
                    ><i class="el-icon-warning" style="color: #f5a623"></i
                    >重复</em
                >
                <em class="repeat" v-show="tag === true"
                    ><i
                        class="el-icon-success"
                        style="color: #46a136 !important"
                    ></i
                ></em> -->
            </p>

            <p>
                <span class="title">最后更新时间</span>
                <span class="cont">{{
                    data.updateTime ? data.updateTime : '- -'
                }}</span>
            </p>
            <p>
                <span class="title">法定代表人</span>
                <span class="cont">{{
                    data.legalPerson ? data.legalPerson : '- -'
                }}</span>
            </p>
        </div>
        <div>
            <p>
                <span class="title">经营状态</span>
                <span class="cont">{{
                    data.companyStatus ? data.companyStatus : '- -'
                }}</span>
            </p>
            <p>
                <span class="title">统一社会信用代码</span>
                <span class="cont">{{
                    data.creditNo ? data.creditNo : '- -'
                }}</span>
            </p>
            <p>
                <span class="title">成立日期</span>
                <span class="cont">{{
                    data.establishDate ? data.establishDate : '- -'
                }}</span>
            </p>
        </div>

        <div>
            <p>
                <span class="title">曾用名</span>
                <span class="cont">{{
                    data.historyNames ? data.historyNames : '- -'
                }}</span>
            </p>
            <p>
                <span class="title">注册资本</span>
                <span class="cont">{{
                    data.realCapital ? data.realCapital : '- -'
                }}</span>
            </p>
            <p>
                <span class="title">所在地</span>
                <span class="cont">{{
                    data.companyAddress ? data.companyAddress : '- -'
                }}</span>
            </p>
        </div>
        <div>
            <p>
                <span class="title">客户类型</span>
                <span class="cont">{{
                    enterpriseType
                        ? enterpriseType == 1
                            ? '企业'
                            : enterpriseType == 2
                            ? '个人'
                            : '外资'
                        : '- -'
                }}</span>
            </p>
            <p>
                <span class="title">行业</span>
                <span class="cont">{{
                    data.industry ? data.industry : '- -'
                }}</span>
            </p>
            <p>
                <span class="title">注册地址</span>
                <span class="cont">{{
                    data.companyAddress ? data.companyAddress : '- -'
                }}</span>
            </p>
        </div>
    </div>
</template>
<script>
import { nameGet, relationCustomer } from '@/api/customer/customer.js';
import { editCustomer, getCustomerAdmin } from '@/api/user/ae/customer';
export default {
    data() {
        return {
            data: {},
            disabled: true,
            isUpData: false,
            adminId: sessionStorage.getItem('adminId'),
            btnP: {},
            // tag: '',
            form: {
                companyName: '',
            },
            enterpriseType: 0,
            originalCustomer: '',
            existCustomer: [],
        };
    },
    methods: {
        getData(data, btnP) {
            this.originalCustomer = data;
            this.enterpriseType = data.enterpriseType;
            this.btnP = btnP;
            if (this.$route.query.type) {
                this.isUpData = true;
            }
            let datas = {
                param: {
                    companyName: data.companyName,
                },
            };
            nameGet(datas).then((res) => {
                this.data = res.data;

                this.form.companyName = JSON.parse(
                    JSON.stringify(res.data.companyName)
                );
            });
        },
        inputCompantName(val) {
            let data = {
                param: {
                    companyName: val,
                    enterpriseType: 1,
                },
            };
            getCustomerAdmin(data).then((res) => {
                if (res.code == 200) {
                    console.log(res.data.list);

                    if (res.data.list && res.data.list.length > 0) {
                        this.existCustomer = res.data.list;
                        this.tag = false;
                    } else {
                        this.tag = true;
                    }
                }
            });
        },
        onUpdateComp() {
            this.disabled = !this.disabled;
            sessionStorage.setItem('customerlist', 1);
        },
        onquxiao() {
            this.disabled = !this.disabled;
            this.form.companyName = this.data.companyName;
        },
        onSubmit() {
            if (this.tag === false) {
                const confirmText = [
                    '系统已存在相同名称的客户，是否关联？',
                    '关联后原客户信息（交易数据）移至历史客户中',
                ];
                const newDatas = [];
                const h = this.$createElement;
                for (const i in confirmText) {
                    newDatas.push(h('p', null, confirmText[i]));
                }
                this.$confirm('提示', {
                    title: '提示',
                    message: h('div', null, newDatas),
                    confirmButtonText: '确定',
                    confirmButtonClass: 'pri',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        console.log(this.existCustomer);
                        let data = {
                            param: {
                                deleteCustomerId: this.originalCustomer.id,
                                customerId: this.existCustomer[0].id,
                            },
                        };
                        relationCustomer(data).then((res) => {
                            if (res.code == 200) {
                                this.$message.success('操作成功');
                                this.$formDetails.commit(
                                    'detailsData',
                                    JSON.stringify(this.existCustomer[0])
                                );
                                this.$emit('againGet');

                                this.disabled = true;
                                this.tag = '';
                                sessionStorage.removeItem('customerlist');
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        });
                    });
            } else {
                let obj = {
                    param: {
                        customerDO: {
                            companyName: this.form.companyName,
                        },
                    },
                };
                obj.param.customerDO.id = this.$formDetails.state.data.id;
                editCustomer(obj).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('修改成功');
                        this.$emit('againGet');
                        this.disabled = true;
                        this.tag = '';
                        sessionStorage.removeItem('customerlist');
                    }
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.basic {
    width: 100%;
    padding-bottom: 10px;
    background: #fff;
    div {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        padding: 7px 24px;
        font-size: 13px;
        p {
            flex: 1;
            display: flex;
            align-items: baseline;
        }
        span {
            display: inline-block;
        }
        .title {
            min-width: 65px;
            margin-right: 27px;
            color: #666666;
        }
        .cont {
            color: #333333;
            overflow: hidden;
            font-weight: 600;
        }
        .name_cont {
            display: flex;
            background: #f9f9f9;
            align-items: center;
            margin-right: 20px;
            font-weight: 600;

            cursor: pointer;
            i {
                font-size: 16px;
                color: #2370eb;
            }
            /deep/ input {
                font-weight: 600 !important;
                padding-left: 5px;
                margin-left: -5px;
            }
        }
    }
    div:first-child {
        align-items: center;
    }
}
.repeat {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-style: normal;
}
</style>
<style lang="scss">
.el-message-box .pri {
    background: #2370eb !important;
    border-color: #2370eb;
}
</style>
