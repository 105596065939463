<template>
    <div class="todo">
        <div v-for="(item, index) in intercourseList">
            <div class="top_title">
                <div>
                    <p
                        v-if="!item.isAdd"
                        style="cursor: pointer;"
                        @click="onComplete(item)"
                        :class="item.completeStatus == 1 ? 'titleColor' : ''"
                    >
                        <i class="el-icon-circle-check"></i>

                        {{
                            item.completeStatus == 0
                                ? '完成计划'
                                : item.completeStatus == 1
                                ? '已完成'
                                : '- -'
                        }}
                    </p>
                </div>
                <p v-if="!item.isAdd && item.completeStatus != 1">
                    <el-button
                        type="text"
                        size="small"
                        style="color: #333333;"
                        @click="onUpData(item)"
                        v-show="!item.disabled"
                    >
                        <i class="el-icon-edit-outline" style="color: #2370EB;"></i>
                        编辑</el-button
                    >
                    <el-button
                        v-show="item.disabled"
                        type="text"
                        size="small"
                        style="color: #2370EB;background:#fff;border-color:#2370EB;padding:5px 5px"
                        @click="item.disabled = false"
                    >
                        取消
                    </el-button>
                    <el-button
                        v-show="item.disabled"
                        type="text"
                        size="small"
                        @click="onSubmit(item)"
                        style="color: #fff;background:#2370EB;border-color:#2370EB;padding:5px 5px"
                    >
                        保存
                    </el-button>
                    <el-button
                        type="text"
                        size="small"
                        style="color: #D0021B;"
                        @click="onDelete(item)"
                        >删除</el-button
                    >
                </p>
                <p
                    v-if="item.isAdd && isUpData && item.completeStatus != 1"
                    style="padding-bottom:8px"
                >
                    <el-button
                        type="text"
                        size="small"
                        style="color: #2370EB;background:#fff;border-color:#2370EB;padding:5px 5px"
                        @click="onCancel()"
                    >
                        取消
                    </el-button>
                    <el-button
                        type="text"
                        size="small"
                        @click="onAddSubmit(item)"
                        style="color: #fff;background:#2370EB;border-color:#2370EB;padding:5px 5px"
                    >
                        保存
                    </el-button>
                </p>
            </div>
            <div class="content_box">
                <div class="content_list">
                    <p style="flex:2">
                        <span class="title">计划标题</span>
                        <el-input
                            style="padding-right:20px;width:220px"
                            size="small"
                            :disabled="!item.disabled"
                            v-model="item.planTitle"
                            placeholder="请输入"
                        ></el-input>
                    </p>
                    <p>
                        <span class="title" v-show="!item.isAdd">创建人</span>
                        <el-input size="small" :disabled="true" v-model="item.adminName"></el-input>
                    </p>
                </div>
                <div class="content_list">
                    <p style="flex:2">
                        <span class="title"
                            ><i class="star" v-show="item.disabled">*</i>计划时间</span
                        >
                        <el-date-picker
                            :disabled="!item.disabled"
                            v-model="item.planTime"
                            type="date"
                            size="small"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            style="width:220px"
                        >
                        </el-date-picker>
                    </p>
                    <p>
                        <span class="title">
                            <i class="star" v-show="item.disabled">*</i>提醒时间</span
                        >
                        <span style="font-size:13px">
                            <el-select
                                :disabled="!item.disabled"
                                v-model="item.remindType"
                                size="small"
                                style="width:60px"
                                placeholder="请选择"
                                clearable
                            >
                                <el-option label="15分钟" value="1"></el-option>
                                <el-option label="30分钟" value="2"></el-option>
                                <el-option label="1小时" value="3"></el-option>
                                <el-option label="2小时" value="4"></el-option>
                                <el-option label="1天" value="5"></el-option>
                            </el-select>
                            前
                        </span>
                    </p>
                </div>
                <div class="content_list">
                    <p style="align-items:flex-start">
                        <span class="title" style="margin-top:5px">
                            <i class="star" v-show="item.disabled">*</i>计划内容</span
                        >
                        <el-input
                            :disabled="!item.disabled"
                            style="font-family: PingFangSC-Regular, PingFang SC;font-size:14px"
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="item.planContent"
                        >
                        </el-input>
                    </p>
                </div>

                <div class="content_list" v-for="(remark, index) in item.customerList">
                    <p>
                        <span class="title">评论</span>
                        <span style="font-size:13px;color:#333;margin-left:15px">{{
                            remark.commentContent
                        }}</span>
                    </p>
                    <p>
                        <span class="title">评论时间</span>
                        <span style="font-size:13px;color:#333">{{ remark.createTime }} </span>
                    </p>
                </div>
                <div class="content_list" style="padding-bottom:0" v-if="!item.isAdd">
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="请输入内容"
                        v-model="item.commentContent"
                    >
                    </el-input>
                </div>
                <div class="content_list" v-if="!item.isAdd">
                    <el-button
                        @click="onComment(item)"
                        type="primary"
                        size="small"
                        style="margin-right:12px;color:#fff;background:#2370EB;border-color:#2370EB;margin:0 auto;margin-top:10px"
                    >
                        发送评论
                    </el-button>
                </div>
            </div>
        </div>
        <!-- 二次确认 -->
        <el-dialog :visible.sync="dialog" width="560px" :before-close="handle" append-to-body>
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    {{ dialogTitle }}
                </div>
            </template>
            <div class="dialog-box">
                <div class="dialog-box">
                    <p v-if="dialogType == 1">是否将该条跟进计划标为已完成？</p>
                    <p v-if="dialogType == 2">是否将该条跟进计划删除？删除后不可恢复！</p>
                </div>
            </div>
            <div class="but">
                <el-button
                    round
                    type="primary"
                    size="small"
                    style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                    @click="dialog = false"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                    size="small"
                    @click="onSubmitDel()"
                    >确定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { planList, editStatus, deletePlan, addFollow } from '@/api/user/follow.js';
import { commentAdd, editFollow } from '@/api/user/follow.js';
export default {
    data() {
        return {
            textarea: '',
            intercourseList: [],
            delData: {},
            dialogType: 1,
            dialogTitle: '',
            dialog: false,
            isUpData: false,
        };
    },
    methods: {
        getData(obj, i) {
            if (this.$route.query.type) {
                this.isUpData = true;
            }
            let data = {
                param: {
                    customerId: obj.id,
                },
            };
            if (i == 0) {
                data.param.completeStatus = i;
            }
            planList(data).then((res) => {
                if (res.data.list.length > 0) {
                    for (let i in res.data.list) {
                        res.data.list[i].disabled = false;
                        res.data.list[i].remindType = '' + res.data.list[i].remindType;
                    }
                    this.intercourseList = res.data.list;
                } else if (this.intercourseList.length == 0) {
                    this.intercourseList.unshift({ disabled: true, isAdd: true });
                }
            });
        },
        // 添加
        onAdd() {
            this.intercourseList.unshift({ disabled: true, isAdd: true });
        },
        onCancel() {
            this.intercourseList.splice(0, 1);
        },
        onAddSubmit(item) {
            let data = {
                param: item,
            };
            data.param.customerId = this.$formDetails.state.data.id;
            data.param.adminName = sessionStorage.getItem('nickName');
            if (!data.param.planTime) {
                this.$message.error('请选择计划时间');
            } else if (!data.param.remindType) {
                this.$message.error('请选择提醒时间');
            } else if (!data.param.planContent) {
                this.$message.error('请输入计划内容');
            } else {
                addFollow(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('添加成功');
                    }
                    this.onClose();
                });
            }
        },
        // 编辑
        onUpData(item) {
            for (let i in this.intercourseList) {
                if (this.intercourseList[i].id == item.id) {
                    this.intercourseList[i].disabled = true;
                    break;
                }
            }
        },
        onSubmit(item) {
            let data = {
                param: item,
            };
            data.param.customerId = this.$formDetails.state.data.id;
            data.param.adminName = sessionStorage.getItem('nickName');
            if (!data.param.remindType) {
                this.$message.error('请选择提醒时间');
            } else if (!data.param.planContent) {
                this.$message.error('请输入计划内容');
            } else {
                editFollow(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('修改成功');
                    }
                    this.onClose();
                });
            }
        },
        // 已完成
        onComplete(item) {
            if (item.completeStatus != 1) {
                this.dialogTitle = '标记为已完成';
                this.dialog = true;
                this.dialogType = 1;
                this.delData = item;
            }
        },

        // 删除
        onDelete(item) {
            this.dialogTitle = '是否删除';
            this.dialog = true;
            this.dialogType = 2;
            this.delData = item;
        },
        onSubmitDel() {
            if (this.dialogType == 2) {
                let data = {
                    param: {
                        customerId: this.$formDetails.state.data.id,
                        id: this.delData.id,
                    },
                };
                deletePlan(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('删除成功');
                    }
                    window.location.reload();
                });
            } else if (this.dialogType == 1) {
                let data = {
                    param: {
                        customerId: this.$formDetails.state.data.id,
                        id: this.delData.id,
                        completeStatus: 1,
                    },
                };

                editStatus(data).then((res) => {
                    if (res.code == 200) {  
                        this.$message.success('标记成功');
                    }
                    this.handle();
                    this.onClose();
                });
            }
        },
        handle() {
            this.dialog = false;
        },
        // 添加评论
        onComment(item) {
            if (!item.commentContent) {
                this.$message.error('请输入评论内容');
            } else {
                let data = {
                    param: {
                        commentContent: item.commentContent,
                        planId: item.id,
                    },
                };
                commentAdd(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('添加成功');
                    }
                    this.onClose();
                });

                this.textarea = '';
            }
        },
        onClose() {
            this.$emit('againGet');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.todo {
    width: 100%;
    height: 100%;

    .top_title {
        font-size: 13px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 12px 17px 12px 10px;
        .el-icon-circle-check {
            position: relative;
            left: -3px;
        }
    }
    .content_box {
        border-bottom: 1px dashed #eeeeee;
        padding: 0 24px;
        .content_list {
            display: flex;
            padding-bottom: 15px;
            p {
                flex: 1;
                display: flex;
                align-items: center;
            }
            .title {
                font-size: 13px;
                color: #666666;
                min-width: 60px;
                position: relative;
                .star {
                    color: red;
                    position: absolute;
                    left: -10px;
                }
            }

            .text {
                color: #333333;
            }
        }
    }
}
.dialog-box {
    padding: 40px 0;
    text-align: center;
    color: #333;
    font-size: 16px;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.titleColor {
    color: #2370eb;
}
</style>
<style>
.todo .el-input.is-disabled .el-input__inner {
    background: #fff !important;
}
.todo .el-input__inner {
    padding-left: 10px !important;
}
.todo .el-input--small .el-input__inner {
    padding-left: 10px !important;
    padding-right: 0 !important;
}
.todo .el-textarea.is-disabled .el-textarea__inner {
    background: #fff !important;
    color: #333;
}
.todo .el-input--small .el-input__icon {
    display: none !important;
}
</style>
