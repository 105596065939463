import http from '@/utils/http.js'

// 往来列表
export function dealingsList(data) {
    return http({
        url: "/jasoboss/customer/intercourse/list",
        method: "post",
        data
    })
}
// 添加往来
export function dealingsAdd(data) {
    return http({
        url: "/jasoboss/customer/intercourse/add",
        method: "post",
        data
    })
}
// 添加说明
export function eplainAdd(data) {
    return http({
        url: "/jasoboss/customer/intercourse/describe/add",
        method: "post",
        data
    })
}


