import { render, staticRenderFns } from "./dealings.vue?vue&type=template&id=439d3123&scoped=true&"
import script from "./dealings.vue?vue&type=script&lang=js&"
export * from "./dealings.vue?vue&type=script&lang=js&"
import style0 from "./dealings.vue?vue&type=style&index=0&id=439d3123&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "439d3123",
  null
  
)

export default component.exports