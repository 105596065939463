<template>
    <div class="mail_box">
        <el-form
            label-position="left"
            style="margin: 0 auto; width: 80%"
            size="small"
            label-width="90px"
        >
            <el-form-item label="原邮箱品牌">
                <el-select
                    v-model="form.primaryEmailBrand"
                    size="small"
                    style="width: 270px"
                    clearable
                    placeholder="请选择原邮箱品牌"
                >
                    <el-option
                        v-for="item in $searchForm.mailboxbrand()"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="到期时间">
                <el-date-picker
                    v-model="form.customerExpireTime"
                    size="small"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 270px"
                    type="date"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="联系备注">
                <el-input
                    style="width: 270px"
                    v-model="form.contactRemark"
                    placeholder="请输入联系备注"
                ></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input
                    style="width: 270px"
                    v-model="form.remark"
                    size="small"
                    placeholder="请输入备注"
                    clearable
                ></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {
                primaryEmailBrand: '',
                customerExpireTime: '',
                contactRemark: '',
                remark: '',
            },
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.form.primaryEmailBrand =
                data.primaryEmailBrand == 0 ? '' : data.primaryEmailBrand;
            this.form.customerExpireTime = data.customerExpireTime || '';
            this.form.contactRemark = data.contactRemark || '';
            this.form.remark = data.remark || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.mail_box {
    width: 80%;
    margin: 25px auto 150px;
    text-align: center;
    .info {
        font-size: 14px;
        margin-bottom: 16px;
        text-align: left;
        label {
            display: inline-block;
            width: 65px;
            font-weight: bold;
            color: #333;
            margin-left: 2px;
        }
    }
}
/deep/ .el-input__prefix {
    right: 5px !important;
    left: auto;
}
</style>
