<template>
    <div class="detail_box">
        <div class="info">
            <label>报备品牌</label>
            <p>阿里企业邮箱</p>
        </div>
        <div class="info">
            <label>报备名称</label>
            <p>{{ tableRow.reportName || '- -' }}</p>
        </div>
        <div class="info">
            <label>客户</label>
            <p>{{ tableRow.companyName || '- -' }}</p>
        </div>
        <div
            class="info"
            v-for="(item, index) in tableRow.nicheDomainBOList"
            :key="item.id"
        >
            <label>域名{{ index + 1 }}</label>
            <p style="padding-right: 60px">{{ item.domain || '- -' }}</p>
            <div class="toast_box">
                <span class="success" v-if="item.auditStatus == 1"
                    ><i class="el-icon-success"></i> 成功</span
                >
                <span class="warning" v-if="item.auditStatus == 2"
                    ><i class="el-icon-warning"></i> 失败</span
                >
                <span class="wait" v-if="item.auditStatus == 0"
                    ><i class="iconfont icon-dengdai"></i> 待处理</span
                >
            </div>
        </div>
        <div class="info">
            <label>所在地</label>
            <p>
                {{
                    data.areaType == 1
                        ? $address.address(tableRow.provinceId, tableRow.cityId)
                        : tableRow.areaType == 2
                        ? '国外/' + tableRow.abroadArea
                        : '- -'
                }}
            </p>
        </div>
        <div class="info">
            <label>报备理由</label>
            <p>{{ tableRow.reportReason || '- -' }}</p>
        </div>
        <div class="info">
            <label>联系人</label>
            <p>{{ tableRow.contactName || '- -' }}</p>
        </div>
        <div class="info">
            <label>联系手机</label>
            <p>{{ tableRow.contactPhone || '- -' }}</p>
        </div>
        <div class="info">
            <label>预计用户数</label>
            <p>
                {{
                    tableRow.estimateNumber
                        ? $MailStatus.echoEstimateNumber(
                              tableRow.estimateNumber
                          ) + '人'
                        : '- -'
                }}
            </p>
        </div>
        <div class="info">
            <label>预计年限</label>
            <p>
                {{
                    tableRow.estimateTerm
                        ? $MailStatus.echoEstimateTerm(tableRow.estimateTerm) +
                          '年'
                        : '- -'
                }}
            </p>
        </div>
        <div class="info">
            <label>预计合作时间</label>
            <p>{{ tableRow.estimateCooperateTime || '- -' }}</p>
        </div>
        <div class="info">
            <label>预计金额</label>
            <p>{{ tableRow.estimateAmount || '- -' }}</p>
        </div>
        <div class="info">
            <label>备注</label>
            <p>{{ tableRow.reportRemark || '- -' }}</p>
        </div>
        <div class="info">
            <label>申请时间</label>
            <p>
                {{
                    tableRow.reportApplyTime ? tableRow.reportApplyTime : '- -'
                }}
            </p>
        </div>
        <div class="info">
            <label>当前状态</label>
            <p>
                {{ $MailStatus.reportStatus(tableRow.reportStatus) }} -
                {{ $MailStatus.trialStatus(tableRow.trialStatus) }}
            </p>
        </div>

        <div class="info">
            <label>回收时间</label>
            <p>
                {{
                    tableRow.reportExpireTime
                        ? tableRow.reportExpireTime
                        : '- -'
                }}
            </p>
        </div>
        <div class="info">
            <label>试用到期时间</label>
            <p>
                {{
                    tableRow.trialExpireTime ? tableRow.trialExpireTime : '- -'
                }}
            </p>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: #2370eb;
                    border-color: #d5d6df;
                    color: #fff;
                    margin-right: 20px;
                "
                @click="onClose"
                >关 闭</el-button
            >
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            // data: {
            //     domainList: [{}],
            // },
            tableRow: {},
        };
    },
    methods: {
        echoData(row) {
            this.tableRow = row;
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.detail_box {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0 50px 0;
    min-height: 100%;
    font-size: 14px;
    .info {
        width: 80%;
        margin: 0 auto;
        display: flex;
        position: relative;
        line-height: 32px;
        label {
            width: 100px;
            color: #666666;
        }
        p {
            flex: 1;
            color: #333;
            font-weight: 600;
        }
        .toast_box {
            position: absolute;
            right: -25px;
            top: 0;
            span {
                display: inline-block;
                width: 75px;
                text-align: center;
                height: 25px;
                line-height: 25px;
                border-radius: 3px;
            }
            .success {
                border: 1px solid #2370eb;
                i {
                    color: #2370eb;
                }
            }
            .warning {
                border: 1px solid #d0021b;
                i {
                    color: #d0021b;
                }
            }
            .wait {
                border: 1px solid #f5a623;
                i {
                    color: #f5a623;
                }
            }
        }
    }
    .bottom-but {
        width: 480px;
        line-height: 48px;
        text-align: right;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>
