<template>
    <div class="add_dealings_box">
        <el-form
            label-position="left"
            style="margin: 0 auto; width: 80%"
            size="small"
            label-width="90px"
        >
            <el-form-item label="往来标题">
                <el-input
                    style="width: 240px"
                    size="small"
                    v-model="form.intercourseTitle"
                    placeholder="请输入"
                ></el-input>
            </el-form-item>
            <el-form-item label="往来时间">
                <el-date-picker
                    v-model="form.intercourseTime"
                    type="date"
                    size="small"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 240px"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="联系方式" required>
                <el-select
                    v-model="form.contactWay"
                    size="small"
                    style="width: 240px"
                    placeholder="请选择"
                >
                    <el-option label="电话" :value="1"></el-option>
                    <el-option label="微信" :value="2"></el-option>
                    <el-option label="邮箱" :value="3"></el-option>
                    <el-option label="QQ" :value="4"></el-option>
                    <el-option label="短信" :value="5"></el-option>
                    <el-option label="拜访" :value="6"></el-option>
                    <el-option label="官网表单" :value="8"></el-option>
                    <el-option label="座机" :value="9"></el-option>
                    <el-option label="其他" :value="7"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="往来内容" required>
                <el-input
                    type="textarea"
                    :rows="4"
                    style="width: 240px"
                    placeholder="请输入内容"
                    v-model="form.intercourseContent"
                >
                </el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            form: {
                intercourseContent: '',
                contactWay: 1,
                intercourseTitle: '',
                intercourseTime: this.$handTime.getFormatDate(),
            },
        };
    },
    components: {},
    created() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.add_dealings_box {
    width: 80%;
    margin: 25px auto 80px;
    text-align: center;
    .info {
        font-size: 14px;
        margin-bottom: 16px;
        text-align: left;
        label {
            display: inline-block;
            width: 65px;
            font-weight: bold;
            color: #333;
            margin-left: 2px;
        }
    }
}
.el-input,
.el-select,
.el-textarea {
    margin-left: -55px;
    resize: none !important;
}
</style>
