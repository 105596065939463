<template>
    <div class="history">
        <el-table
            highlight-current-row
            :data="data"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column
                property="createTime"
                label="操作时间"
                width="110"
                show-overflow-tooltip
            ></el-table-column>
            <el-table-column
                property="firstAdminName"
                label="操作人员"
                width="90"
            ></el-table-column>
            <el-table-column :formatter="journal" label="操作日志"></el-table-column>
        </el-table>
    </div>
</template>
<script>
import { historyCustList } from '@/api/user/history.js';
export default {
    data() {
        return {
            data: [],
        };
    },
    methods: {
        getData(obj) {
            let data = {
                param: {
                    customerId: obj.id,
                },
            };
            historyCustList(data).then((res) => {
                this.data = res.data.list;
            });
        },
        journal(item) {
            let str = item.content;
            let text = str
                .replace('%s', item.firstAdminName ? item.firstAdminName : '', 1)
                .replace('%s', item.secondAdminName ? item.secondAdminName : '', 2);
            return text;
        },
    },
};
</script>
