<template>
    <div class="todo">
        <div v-for="(item, index) in dealingsList" style="padding-top:5px">
            <div class="title">
                <span> </span>
                <span style="margin-right:17px" v-show="isUpData">
                    <el-button
                        v-show="item.disabled"
                        type="text"
                        size="small"
                        style="color: #2370EB;background:#fff;border-color:#2370EB;padding:5px 5px"
                        @click="onCancel(item), (item.disabled = false)"
                    >
                        取消
                    </el-button>
                    <el-button
                        v-show="item.disabled"
                        type="text"
                        size="small"
                        @click="onAddSubmit(item)"
                        style="color: #fff;background:#2370EB;border-color:#2370EB;padding:5px 5px"
                    >
                        保存
                    </el-button>
                </span>
            </div>

            <div class="content_box">
                <div class="content_list">
                    <p style="flex:2">
                        <span class="title">往来标题</span>
                        <el-input
                            style="padding-right:20px;width:220px"
                            size="small"
                            :disabled="!item.disabled"
                            v-model="item.intercourseTitle"
                            placeholder="请输入"
                        ></el-input>
                    </p>
                    <p>
                        <span class="title">跟进人</span>
                        <el-input size="small" :disabled="true" v-model="item.adminName"></el-input>
                    </p>
                </div>
                <div class="content_list">
                    <p style="flex:2">
                        <span class="title">往来时间</span>
                        <el-date-picker
                            :disabled="!item.disabled"
                            v-model="item.intercourseTime"
                            type="date"
                            size="small"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            style="width:220px"
                        >
                        </el-date-picker>
                    </p>
                    <p>
                        <span class="title">
                            <i class="star" v-show="item.disabled">*</i>联系方式</span
                        >
                        <el-select
                            v-model="item.contactWay"
                            size="small"
                            :disabled="!item.disabled"
                            style="width:100px"
                            placeholder="请选择"
                            clearable
                        >
                            <el-option label="电话" :value="1"></el-option>
                            <el-option label="微信" :value="2"></el-option>
                            <el-option label="邮箱" :value="3"></el-option>
                            <el-option label="QQ" :value="4"></el-option>
                            <el-option label="短信" :value="5"></el-option>
                            <el-option label="拜访" :value="6"></el-option>
                            <el-option label="官网表单" :value="8"></el-option>
                            <el-option label="座机" :value="9"></el-option>
                            <el-option label="其他" :value="7"></el-option>
                        </el-select>
                    </p>
                </div>
                <div class="content_list">
                    <p style="align-items:flex-start">
                        <span class="title" style="margin-top:5px">
                            <i class="star" v-show="item.disabled">*</i>往来内容</span
                        >
                        <el-input
                            :disabled="!item.disabled"
                            style="font-family: PingFangSC-Regular, PingFang SC;font-size:14px"
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="item.intercourseContent"
                        >
                        </el-input>
                    </p>
                </div>
                <div class="content_list" v-for="(remark, index) in item.describeList">
                    <p>
                        <span class="title">说明</span>
                        <span style="font-size:13px;margin-left:15px;color:#333">{{
                            remark.describeContent
                        }}</span>
                    </p>
                </div>
                <div style="padding-bottom:0" v-show="!item.isAdd" class="content_list">
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="请输入说明内容"
                        v-model="item.describeContent"
                    >
                    </el-input>
                </div>

                <div class="content_list" v-show="!item.isAdd" style="margin-top:10px">
                    <el-button
                        @click="onExplain(item)"
                        type="primary"
                        size="mini"
                        style="margin-right:12px;color:#fff;background:#2370EB;border-color:#2370EB;margin:0 auto"
                    >
                        添加说明
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { dealingsList, dealingsAdd } from '@/api/user/custDealings.js';
import { eplainAdd } from '@/api/user/custDealings.js';
export default {
    data() {
        return {
            dealingsList: [],
            isUpData: false,
        };
    },
    methods: {
        // 获取往来记录
        getData(obj) {
            if (this.$route.query.type) {
                this.isUpData = true;
            }
            let data = {
                param: {
                    customerId: obj.id,
                },
            };
            dealingsList(data).then((res) => {
                if (res.data.list.length > 0) {
                    for (let i in res.data.list) {
                        res.data.list[i].disabled = false;
                    }
                    this.dealingsList = res.data.list;
                } else if (this.dealingsList.length == 0) {
                    this.dealingsList.unshift({
                        disabled: true,
                        isAdd: true,
                        contactWay: 1,
                    });
                }
            });
        },
        // 添加
        onAdd() {
            this.dealingsList.unshift({ disabled: true, isAdd: true, contactWay: 1 });
        },
        onCancel() {
            this.dealingsList.splice(0, 1);
        },
        onAddSubmit(item) {
            let data = {
                param: item,
            };
            if (!item.contactWay) {
                this.$message.error('请选择联系方式');
            } else if (!item.intercourseContent) {
                this.$message.error('请输入往来内容');
            } else {
                data.param.name = sessionStorage.getItem('nickName');
                data.param.describeList = [];
                data.param.customerId = this.$formDetails.state.data.id;

                dealingsAdd(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('添加成功');
                    }
                    this.onClose();
                });
            }
        },
        // 添加说明
        onExplain(item) {
            if (!item.describeContent) {
                this.$message.error('请输入往来说明');
            } else {
                let data = {
                    param: {
                        describeContent: item.describeContent,
                        intercourseId: item.id,
                    },
                };
                eplainAdd(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('添加成功');
                    }
                    this.onClose();
                });

                item.describeContent = '';
            }
        },
        onClose() {
            this.$emit('againGet');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.todo {
    width: 100%;
    height: 100%;

    .top_title {
        font-size: 13px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 12px 17px 12px 10px;
        .el-icon-circle-check {
            position: relative;
            left: -3px;
        }
    }
    .content_box {
        border-bottom: 1px dashed #eeeeee;
        padding: 0 24px;
        .content_list {
            display: flex;
            padding-bottom: 15px;
            p {
                flex: 1;
                display: flex;
                align-items: center;
            }
            .title {
                font-size: 13px;
                color: #666666;
                min-width: 60px;
                position: relative;
                .star {
                    color: red;
                    position: absolute;
                    left: -10px;
                }
            }
            .text {
                color: #333333;
            }
        }
    }
}
.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>
