import http from '@/utils/http.js'

// 跟进列表
export function planList(data) {
    return http({
        url: "/jasoboss/customer/plan/list",
        method: "post",
        data
    })
}

//添加跟进
export function addFollow(data) {
    return http({
        url: "/jasoboss/customer/plan/add",
        method: "post",
        data
    })
}
//添加评论
export function commentAdd(data) {
    return http({
        url: "/jasoboss/customer/plan/comment/add",
        method: "post",
        data
    })
}
//修改跟进计划
export function editFollow(data) {
    return http({
        url: "/jasoboss/customer/plan/update",
        method: "post",
        data
    })
}
// 修改状态
export function editStatus(data) {
    return http({
        url: "/jasoboss/customer/plan/update/status",
        method: "post",
        data
    })
}
// 删除
export function deletePlan(data) {
    return http({
        url: "/jasoboss/customer/plan/delete",
        method: "post",
        data
    })
}
