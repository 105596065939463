<template>
    <div class="report">
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column
                label="操作时间"
                width="160"
                prop="createTime"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                label="操作人员"
                prop="firstAdminName"
                width="100"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作日志" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div>{{ handle(scope.row) }}</div>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { historyCustList } from '@/api/user/history.js';
export default {
    components: {},
    data() {
        return {
            data: {},
            tableData: [],
        };
    },
    methods: {
        getData(row) {
            this.data = row;
            let data = {
                param: {
                    customerId: row.id,
                },
                pageNum: 0,
                pageSize: 0,
            };
            historyCustList(data).then((res) => {
                this.tableData = res.data.list;
            });
        },
        handle(item) {
            let str = item.content;
            let text = str
                .replace(
                    '%s',
                    item.firstAdminName ? item.firstAdminName : '',
                    1
                )
                .replace(
                    '%s',
                    item.secondAdminName ? item.secondAdminName : '',
                    2
                );
            return text;
        },
    },
};
</script>
<style lang="scss" scoped>
.report {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.item {
    p + p {
        margin-top: 10px;
    }
    span {
        display: inline-block;
        vertical-align: middle;
        width: 70px;
        font-size: 12px;
        text-align: center;
        height: 25px;
        line-height: 25px;
        border-radius: 3px;
        margin-left: 10px;
    }
    .success {
        border: 1px solid #2370eb;
        i {
            color: #2370eb;
        }
    }
    .warning {
        border: 1px solid #d0021b;
        i {
            color: #d0021b;
        }
    }
    .wait {
        border: 1px solid #f5a623;
        i {
            color: #f5a623;
        }
    }
    .faid {
        border: 1px solid #d0021b;
        i {
            color: #d0021b;
        }
    }
}
</style>
