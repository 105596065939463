<template>
    <div class="basic">
        <div class="add" v-show="isAdd">
            <div>
                <p>
                    <span class="title">域名</span>
                    <span class="name_cont">
                        <el-input
                            v-model="domain"
                            size="mini"
                            style="max-width: 200px; padding: 0"
                            placeholder="请输入内容"
                        ></el-input>
                        <el-button
                            @click="onAddDomain()"
                            size="mini"
                            type="text"
                            style="color: #2162cb; margin-left: 8px"
                            >保存</el-button
                        >
                        <el-button
                            @click="onquxiao()"
                            size="mini"
                            type="text"
                            style="color: #d0021b"
                            >取消</el-button
                        >
                    </span>
                </p>
            </div>
        </div>
        <div class="main">
            <div>
                <p>
                    <span class="title">域名</span>
                    <span class="name_cont">
                        <el-input
                            v-model="customerData.companyDomain"
                            size="mini"
                            style="max-width: 200px; padding: 0"
                            placeholder="请输入内容"
                            :disabled="disabled"
                        ></el-input>
                        <i
                            class="el-icon-edit-outline"
                            @click="disabled = !disabled"
                            v-show="disabled && data2.adminId == adminId"
                        ></i>
                        <el-button
                            @click="onUpData(data)"
                            size="mini"
                            type="text"
                            v-show="!disabled"
                            style="color: #2162cb"
                            >保存</el-button
                        >
                        <el-button
                            @click="onquxiaod()"
                            size="mini"
                            type="text"
                            v-show="!disabled"
                            style="color: #d0021b"
                            >取消</el-button
                        >
                        <el-button
                            @click="onRefresh(1, customerData.companyDomain)"
                            size="mini"
                            type="text"
                            style="color: #d0021b"
                            >刷新</el-button
                        >
                    </span>
                </p>
                <p>
                    <span class="title">邮箱品牌</span>
                    <span class="cont">{{
                        data.domainMX
                            ? $tableDataHandle.dominBrand(data.domainMX)
                            : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">负责人</span>
                    <span class="cont">{{
                        data.icpOwner ? data.icpOwner : '- -'
                    }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span class="title">备案名称</span>
                    <span class="cont">{{
                        data.icpRecordWebsite ? data.icpRecordWebsite : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">域名注册平台</span>
                    <span class="cont">{{
                        data.whoisRegistrant ? data.whoisRegistrant : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">whois</span>
                    <span class="cont"
                        >{{
                            data.icpDomainRecordTime
                                ? data.icpDomainRecordTime
                                : '- -'
                        }}
                        <i class="el-icon-right"></i>
                        {{
                            data.whoisDueDate ? data.whoisDueDate : '- -'
                        }}</span
                    >
                </p>
            </div>

            <div>
                <p>
                    <span class="title">联系人</span>
                    <span class="cont">{{
                        data.whoisContacts ? data.whoisContacts : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">联系方式</span>
                    <span class="cont">{{
                        data.whoisContact ? data.whoisContact : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">联系邮箱</span>
                    <span class="cont">{{
                        data.whoisEmail ? data.whoisEmail : '- -'
                    }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span class="title">备案号</span>
                    <span class="cont">{{
                        data.icpRecordNo ? data.icpRecordNo : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">域名备案时间</span>
                    <span class="cont">{{
                        data.icpDomainRecordTime
                            ? data.icpDomainRecordTime
                            : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">域名服务器</span>
                    <span class="cont">{{
                        data.whoisDomainServer ? data.whoisDomainServer : '- -'
                    }}</span>
                </p>
            </div>
        </div>
        <div class="deputy" v-for="item in customerData.customerDomainList">
            <div>
                <p>
                    <span class="title">关联域名</span>
                    <span class="name_cont">
                        <el-input
                            v-model="item.domain"
                            size="mini"
                            style="max-width: 200px; padding: 0"
                            placeholder="请输入内容"
                            :disabled="item.isEdit"
                        ></el-input>
                        <i
                            class="el-icon-edit-outline"
                            @click="onUpDataInput(item)"
                            v-show="item.isEdit && item.adminId == adminId"
                        ></i>
                        <el-button
                            @click="onDeputyUpData(item)"
                            size="mini"
                            type="text"
                            v-show="!item.isEdit"
                            style="color: #2162cb"
                            >保存</el-button
                        >
                        <el-button
                            @click="oncancle()"
                            size="mini"
                            type="text"
                            v-show="!item.isEdit"
                            style="color: #d0021b"
                            >取消</el-button
                        >
                        <el-button
                            @click="onDelete(item)"
                            size="mini"
                            type="text"
                            v-show="item.isEdit && item.adminId == adminId"
                            style="color: #d0021b"
                            >删除</el-button
                        >
                        <el-button
                            @click="onRefresh(1, item.domain)"
                            size="mini"
                            type="text"
                            style="color: #d0021b"
                            >刷新</el-button
                        >
                    </span>
                </p>
                <p>
                    <span class="title">邮箱品牌</span>
                    <span class="cont">{{
                        item.whois
                            ? $tableDataHandle.dominBrand(item.whois.domainMX)
                            : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">负责人</span>
                    <span class="cont" v-show="item.whois">{{
                        item.whois ? item.whois.icpOwner : '- -'
                    }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span class="title">备案名称</span>
                    <span class="cont">{{
                        item.whois ? item.whois.icpRecordWebsite : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">域名注册平台</span>
                    <span class="cont">{{
                        item.whois ? item.whois.whoisRegistrant : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">whois</span>
                    <span class="cont"
                        >{{
                            item.whois ? item.whois.icpDomainRecordTime : '- -'
                        }}
                        <i class="el-icon-right"></i>
                        {{ item.whois ? item.whois.whoisDueDate : '- -' }}</span
                    >
                </p>
            </div>

            <div>
                <p>
                    <span class="title">联系人</span>
                    <span class="cont">{{
                        item.whois ? item.whois.whoisContacts : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">联系方式</span>
                    <span class="cont">{{
                        item.whois ? item.whois.whoisContact : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">联系邮箱</span>
                    <span class="cont">{{
                        item.whois ? item.whois.whoisEmail : '- -'
                    }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span class="title">备案号</span>
                    <span class="cont">{{
                        item.whois ? item.whois.icpRecordNo : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">域名备案时间</span>
                    <span class="cont">{{
                        item.whois ? item.whois.icpDomainRecordTime : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">域名服务器</span>
                    <span class="cont">{{
                        item.whois ? item.whois.whoisDomainServer : '- -'
                    }}</span>
                </p>
            </div>
        </div>
        <!-- 二次确认 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handle"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    删除域名
                </div>
            </template>
            <div class="dialog-box">
                <div class="dialog-box">
                    <p>是否将该条域名删除？删除后不可恢复！</p>
                </div>
            </div>
            <div class="but">
                <el-button
                    round
                    type="primary"
                    size="small"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="dialog = false"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="small"
                    @click="onSubmitDel()"
                    >确定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { domainGet, domainMustGet } from '@/api/customer/customer.js';
import { editCustomer, domainDetele } from '@/api/user/ae/customer';
export default {
    data() {
        return {
            data: {},
            disabled: true,
            customerData: {},
            isAdd: false,
            domain: '',
            isUpData: false,
            dialog: false,
            obj: {},
            btnP: {},
            data2: {},
            adminId: sessionStorage.getItem('adminId'),
            departmentId: sessionStorage.getItem('departmentId'),
            searchUserCustomer:
                Boolean(sessionStorage.getItem('searchUserCustomer')) || false,
        };
    },
    methods: {
        getData(data, btnP) {
            this.btnP = btnP;

            if (this.$route.query.type) {
                this.isUpData = true;
            }
            this.data2 = data;
            if (data.enterpriseDomain) {
                this.data = data.enterpriseDomain;
            }

            if (data.customerDomainList.length > 0) {
                for (let i = 0; i < data.customerDomainList.length; i++) {
                    data.customerDomainList[i].isEdit = true;
                    data.customerDomainList[i].whois = {};
                    data.customerDomainList[i].whois =
                        data.customerDomainList[i].enterpriseDomain;
                }
            }
            setTimeout(() => {
                this.customerData = data;
            }, 1000);
        },
        onquxiaod() {
            this.disabled = !this.disabled;
            this.customerData.companyDomain = this.data.domain;
        },
        onRefresh(i, row) {
            if (i == 1) {
                let data = {
                    param: {
                        domain: row,
                    },
                };
                domainMustGet(data).then((res) => {
                    this.data = res.data;
                });
            }
        },
        oncancle() {
            this.customerData.customerDomainList = [];
            this.customerData.customerDomainList = JSON.parse(
                JSON.stringify(
                    this.data2.customerDomainList.map((item) => {
                        item.isEdit = true;
                        return item;
                    })
                )
            );
        },
        onUpDataInput(item) {
            let a = JSON.stringify(this.customerData);
            let b = JSON.parse(a);
            // console.log(b);
            for (let i in b.customerDomainList) {
                if (item.domain == b.customerDomainList[i].domain) {
                    b.customerDomainList[i].isEdit = false;
                }
            }
            this.customerData = b;
            // console.log(this.customerData);
        },
        onUpData() {
            if (!this.customerData.companyDomain) {
                return this.$message.error('请输入域名');
            }
            let obj = {
                param: {
                    customerDO: {
                        companyName: this.customerData.companyName,
                        companyDomain: this.customerData.companyDomain,
                    },
                },
            };
            obj.param.customerDO.id = this.$formDetails.state.data.id;
            editCustomer(obj).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.$emit('againGet');
                    this.disabled = true;
                }
            });
        },
        onDeputyUpData(item) {
            let obj = {
                param: {
                    customerDO: {
                        companyName: this.customerData.companyName,
                    },
                    customerDomainList: this.customerData.customerDomainList,
                },
            };
            obj.param.customerDO.id = this.$formDetails.state.data.id;
            editCustomer(obj).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.$emit('againGet');
                    this.disabled = true;
                }
            });
        },
        // 添加域名
        onAdd() {
            this.isAdd = true;
        },
        onquxiao() {
            this.isAdd = false;
        },
        onAddDomain() {
            if (this.domain == '') {
                return this.$message.error('请输入域名');
            }
            let obj = {
                param: {
                    customerDO: {
                        companyName: this.customerData.companyName,
                    },
                    customerDomainList: this.customerData.customerDomainList,
                },
            };
            obj.param.customerDO.id = this.$formDetails.state.data.id;
            obj.param.customerDomainList.push({ domain: this.domain });
            editCustomer(obj).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    window.location.reload();
                }
            });
        },
        // 删除域名
        onDelete(item) {
            this.dialog = true;
            this.obj = item;
        },
        onSubmitDel() {
            let data = {
                param: {
                    id: this.obj.id,
                },
            };
            domainDetele(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('删除成功');
                    this.$emit('againGet');
                    this.dialog = false;
                    this.isAdd = false;
                    this.domain = '';
                }
            });
        },
        handle() {
            this.dialog = false;
            this.$emit('againGet');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.basic {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    .main {
        padding-bottom: 10px;
    }
    .add,
    .main,
    .deputy {
        div {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            justify-content: space-between;
            padding: 7px 24px;
            font-size: 13px;
            p {
                flex: 1;
                display: flex;
            }
            span {
                display: inline-block;
            }
            .title {
                min-width: 65px;
                margin-right: 27px;
                color: #666666;
            }
            .cont {
                color: #333333;
                font-weight: 600;
            }
            .name_cont {
                display: flex;
                background: #f9f9f9;
                align-items: center;
                margin-right: 20px;
                cursor: pointer;
                i {
                    font-size: 16px;
                    color: #2370eb;
                }
                /deep/ input {
                    font-weight: 600 !important;
                    padding-left: 5px;
                    margin-left: -5px;
                }
            }
        }
        div:first-child {
            align-items: center;
        }
    }
    .deputy {
        border-top: 1px dashed #eeeeee;
        padding: 10px 0;
    }
    .add {
        border-bottom: 1px dashed #eeeeee;
        padding: 10px 0;
        .name_cont {
            display: flex;
            align-items: center;
            margin-right: 20px;
            background: #fff !important;
            cursor: pointer;
        }
    }
}
.dialog-box {
    padding: 40px 0;
    text-align: center;
    color: #333;
    font-size: 16px;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
