var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"teade"},[_c('div',{staticClass:"teade_main"},[_c('div',{staticClass:"product_box"},[_c('h2',[_vm._v("已购买产品")]),_c('div',[_c('div',{style:({
                        background:
                            'url(' +
                            require('@/img/tmmail.png') +
                            ') no-repeat',
                        backgroundSize: 'cover',
                    }),on:{"click":function($event){return _vm.toAlready('tm', _vm.tmProductNum)}}},[_c('h2',[_vm._v(_vm._s(_vm.tmProductNum))]),_c('p',[_vm._v("腾讯邮箱")])]),_c('div',{style:({
                        background:
                            'url(' +
                            require('@/img/alimail.png') +
                            ') no-repeat',
                        backgroundSize: 'cover',
                    }),on:{"click":function($event){return _vm.toAlready('ali', _vm.aliProductNum)}}},[_c('h2',[_vm._v(_vm._s(_vm.aliProductNum))]),_c('p',[_vm._v("阿里邮箱")])]),_c('div',{style:({
                        background:
                            'url(' +
                            require('@/img/wymail.png') +
                            ') no-repeat',
                        backgroundSize: 'cover',
                    }),on:{"click":function($event){return _vm.toAlready('wy', _vm.wyProductNum)}}},[_c('h2',[_vm._v(_vm._s(_vm.wyProductNum))]),_c('p',[_vm._v("网易邮箱")])]),_c('div',{style:({
                        background:
                            'url(' +
                            require('@/img/domainmail.png') +
                            ') no-repeat',
                        backgroundSize: 'cover',
                    }),on:{"click":function($event){return _vm.toAlready('domain', _vm.domainProductNum)}}},[_c('h2',[_vm._v(_vm._s(_vm.domainProductNum))]),_c('p',[_vm._v("域名")])])])]),_c('div',{staticClass:"relation_clue_box"},[_c('h2',[_vm._v("关联线索")]),_c('div',[_c('div',{style:({
                        background:
                            'url(' +
                            require('@/img/xsline.png') +
                            ') no-repeat',
                        backgroundSize: 'cover',
                    }),on:{"click":_vm.toSaleClue}},[_c('h2',[_vm._v(_vm._s(_vm.saleClueNum))]),_c('p',[_vm._v("销售线索")])]),_c('div',{style:({
                        background:
                            'url(' +
                            require('@/img/scline.png') +
                            ') no-repeat',
                        backgroundSize: 'cover',
                    }),on:{"click":_vm.toMarketClue}},[_c('h2',[_vm._v(_vm._s(_vm.marketClueNum))]),_c('p',[_vm._v("市场线索")])])])]),_c('div',{staticClass:"relation_contract_box"},[_c('h2',[_vm._v("关联合同")]),_c('div',[_c('div',{style:({
                        background:
                            'url(' +
                            require('@/img/glcontr.png') +
                            ') no-repeat',
                        backgroundSize: 'cover',
                    }),on:{"click":_vm.toContr}},[_c('h2',[_vm._v(_vm._s(_vm.contrNum))]),_c('p',[_vm._v("关联合同")])])])]),_c('div',{staticClass:"relation_contract_box"},[_c('h2',[_vm._v("销售订单")]),_c('div',[_c('div',{style:({
                        background:
                            'url(' +
                            require('@/img/glcontr.png') +
                            ') no-repeat',
                        backgroundSize: 'cover',
                    }),on:{"click":_vm.toOrder}},[_c('h2',[_vm._v(_vm._s(_vm.orderNum))]),_c('p',[_vm._v("销售订单")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }