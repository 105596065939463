<template>
    <div class="contact_box2">
        <div class="contact_main">
            <p v-for="(item, index) in customerMainContactList" :key="item.id">
                <span v-show="!item.isEdit">
                    <i
                        :class="$tableDataHandle.contactWayColor(item).icon"
                        :style="{
                            color: $tableDataHandle.contactWayColor(item).color,
                        }"
                    ></i
                    >&nbsp;&nbsp; {{ item.contactContent }}&nbsp;&nbsp;{{
                        item.contactName
                    }}
                </span>

                <span v-show="!item.isEdit && item.adminId == adminId">
                    <i class="el-icon-edit-outline" @click="onEdit(index)"></i>
                    <i class="el-icon-delete" @click="delContact(item)"></i>
                </span>

                <el-select
                    v-show="item.isEdit"
                    v-model="item.contactWay"
                    size="small"
                    style="width: 80px"
                    placeholder="方式"
                >
                    <el-option label="电话" :value="1"></el-option>
                    <el-option label="微信" :value="2"></el-option>
                    <el-option label="邮箱" :value="3"></el-option>
                    <el-option label="QQ" :value="4"></el-option>
                    <el-option label="短信" :value="5"></el-option>
                    <el-option label="拜访" :value="6"></el-option>
                    <el-option label="座机" :value="9"></el-option>
                    <el-option label="其他" :value="7"></el-option>
                </el-select>
                <el-input
                    v-show="item.isEdit"
                    style="margin-left: 5px; width: 110px"
                    v-model="item.contactContent"
                    placeholder="联系内容"
                    size="small"
                ></el-input>
                <el-input
                    v-show="item.isEdit"
                    style="margin-left: 5px; width: 80px"
                    v-model="item.contactName"
                    placeholder="联系人"
                    size="small"
                ></el-input>

                <span v-show="item.isEdit">
                    <el-button
                        size="mini"
                        type="text"
                        @click="customerMainContactOpt(item, index)"
                        style="color: #2162cb; margin-left: 5px"
                        >保存</el-button
                    >
                    <el-button
                        size="mini"
                        type="text"
                        @click="onCancel(item, index)"
                        style="color: #d0021b; margin-left: 5px"
                        >取消</el-button
                    >
                    <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-delete"
                        style="color: #d0021b; margin-left: 5px"
                        @click="delContact(item, index)"
                    ></el-button>
                </span>
            </p>
        </div>
    </div>
</template>

<script>
import {
    customerMainContactDelete,
    customerMainContactUpdate,
    customerMainContactAdd,
} from '@/api/customer/customer';
export default {
    name: '',
    props: {},
    data() {
        return {
            customerMainContactList: [],
            initialData: [],
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.customerMainContactList = JSON.parse(JSON.stringify(data));
        },
        delContact(item, index) {
            if (item.id) {
                this.$confirm(
                    '是否确认删除所选联系方式？删除后数据不可恢复',
                    '提示',
                    {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }
                )
                    .then(() => {
                        let data = {
                            param: {
                                id: item.id,
                            },
                        };
                        customerMainContactDelete(data).then((res) => {
                            if (res.code == 200) {
                                this.$message.success('操作成功');
                                this.$emit('againGet');
                            }
                        });
                    })
                    .catch(() => {});
            } else {
                this.customerMainContactList.splice(index, 1);
                this.getData(this.customerMainContactList);
            }
        },
        onEdit(index) {
            this.customerMainContactList[index].isEdit = true;
            this.customerMainContactList = JSON.parse(
                JSON.stringify(this.customerMainContactList)
            );
        },
        onCancel(item, index) {
            if (item.id) {
                this.customerMainContactList[index].isEdit = false;
                this.customerMainContactList = JSON.parse(
                    JSON.stringify(this.customerMainContactList)
                );
            } else {
                this.customerMainContactList.splice(index, 1);
                this.customerMainContactList = JSON.parse(
                    JSON.stringify(this.customerMainContactList)
                );
            }
        },
        customerMainContactOpt(item, index) {
            for (let i in this.customerMainContactList) {
                if (
                    i != index &&
                    item.contactContent ==
                        this.customerMainContactList[i].contactContent
                ) {
                    return this.$message.error('请勿重复添加联系人');
                }
            }
            if (item.id) {
                let data = {
                    param: {
                        id: item.id,
                        contactWay: item.contactWay,
                        contactName: item.contactName,
                        contactContent: item.contactContent,
                    },
                };
                customerMainContactUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.$emit('againGet');
                    }
                });
            } else {
                let data = {
                    param: {
                        customerId: this.$formDetails.state.data.id,
                        contactWay: item.contactWay,
                        contactName: item.contactName,
                        contactContent: item.contactContent,
                    },
                };
                customerMainContactAdd(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        this.$emit('againGet');
                    }
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contact_box2 {
    width: 100%;
    background: #fff;
    overflow: hidden;
    .contact_main {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 7px 24px 0px;
        font-size: 13px;
        flex-wrap: wrap;
        /deep/ .el-input__inner {
            padding: 0 8px !important;
        }
        p {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            span:nth-child(2) {
                flex: 1;
                display: flex;

                .el-icon-edit-outline {
                    color: #2162cb;
                    cursor: pointer;
                    margin-left: 8px;
                }
                .el-icon-delete {
                    color: red;
                    cursor: pointer;
                    margin-left: 8px;
                }
                padding-right: 10px;
            }
        }
    }
}
.icon-dianhua,
.icon-weixin,
.icon-QQ,
.icon-youxiang,
.icon-qita,
.icon-youxiang1,
.icon-yuyuebaifang,
.icon-qita1,
.icon-diannao,
.icon-duanxinxiaoxixinxi {
    position: static;
    vertical-align: middle;
    left: auto;
    top: auto;
    transform: translateY(0);
}
</style>
