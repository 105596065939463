<template>
    <div class="report">
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column
                label="报备名称"
                min-width="150"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        @click="onInformation(scope.row)"
                        >{{ scope.row.reportName }}</el-button
                    >
                </template>
            </el-table-column>

            <el-table-column label="域名" show-overflow-tooltip min-width="120">
                <template slot-scope="scope">
                    <div v-if="scope.row.productType == 2">
                        <el-tooltip effect="dark" placement="bottom">
                            <div>
                                {{ returnDomain(scope.row) }}
                            </div>
                            <div slot="content" class="item">
                                <p
                                    v-for="item in scope.row.nicheDomainBOList"
                                    :key="item.id"
                                >
                                    <span
                                        style="
                                            display: inline-block;
                                            width: 150px;
                                            text-align: left;
                                        "
                                    >
                                        {{ item.domain }}
                                    </span>
                                    <span
                                        class="success"
                                        v-if="item.auditStatus == 1"
                                    >
                                        <i class="el-icon-success"></i>
                                        成功
                                    </span>
                                    <span
                                        class="faid"
                                        v-if="item.auditStatus == 2"
                                    >
                                        <i class="el-icon-warning"></i>
                                        失败
                                    </span>
                                    <span
                                        class="wait"
                                        v-if="item.auditStatus == 0"
                                        ><i class="iconfont icon-dengdai"></i>
                                        待处理</span
                                    >
                                </p>
                            </div>
                        </el-tooltip>
                    </div>
                    <div v-else>{{ returnDomain(scope.row) }}</div>
                </template>
            </el-table-column>
            <el-table-column
                label="报备品牌"
                min-width="80"
                show-overflow-tooltip
                ><template slot-scope="scope">
                    <span>{{
                        scope.row.productType == 1
                            ? '腾讯'
                            : scope.row.productType == 2
                            ? '阿里'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="报备时间"
                show-overflow-tooltip
                min-width="110"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.reportApplyTime">{{
                        scope.row.reportApplyTime.slice(0, 10)
                    }}</span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column
                label="回收时间"
                show-overflow-tooltip
                min-width="110"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.reportExpireTime">{{
                        scope.row.reportExpireTime.slice(0, 10)
                    }}</span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" show-overflow-tooltip min-width="180">
                <template slot-scope="scope">
                    <span>
                        {{ $MailStatus.reportStatus(scope.row.reportStatus) }}
                        -
                        {{
                            $MailStatus.trialStatus(scope.row.trialStatus)
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column
                label="试用到期时间"
                show-overflow-tooltip
                min-width="110"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.trialExpireTime">{{
                        scope.row.trialExpireTime.slice(0, 10)
                    }}</span>
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column label="报备库" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.libraryName }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="报备人"
                prop="adminName"
                show-overflow-tooltip
            >
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 报备信息 -->
        <el-drawer
            :visible.sync="drawerDetails"
            :direction="direction"
            :before-close="handleClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    报备信息
                </div>
            </template>
            <TmDetails
                @close="handleClose"
                ref="tmdetails"
                v-if="row.productType == 1"
            />

            <AliDetails
                @close="handleClose"
                ref="alidetails"
                v-if="row.productType == 2"
            />
        </el-drawer>
        <!-- 历史 -->
        <el-drawer
            :visible.sync="drawerHistory"
            :direction="direction"
            :before-close="handleClose"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        font-weight: 550;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    业务操作历史
                </div>
            </template>
            <History @close="handleClose" ref="history" />
        </el-drawer>
    </div>
</template>
<script>
import TmDetails from './details.vue';
import History from './history.vue';
import AliDetails from './alidetails.vue';
import { reportList, reportGet, reportUpdate } from '@/api/report/report';
export default {
    components: {
        TmDetails,
        History,
        AliDetails,
    },
    data() {
        return {
            data: {},
            tableData: [],
            pagesize: 50,
            total: 0,
            currentPage: 1,
            drawerHistory: false,
            drawerDetails: false,
            direction: 'rtl',
            row: {},
            searchUserCustomer:
                Boolean(sessionStorage.getItem('searchUserCustomer')) || false,
            depSearchCustomer:
                Boolean(sessionStorage.getItem('depSearchCustomer')) || false,
        };
    },
    methods: {
        getData(row, btnP) {
            this.data = row;
            let data = {
                param: {
                    all: 1,
                    customerId: row.id,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.searchUserCustomer && !this.depSearchCustomer) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                if (!this.depSearchCustomer) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            reportList(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData(this.data);
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData(this.data);
        },
        // 操作历史
        onHistory(row) {
            this.drawerHistory = true;
            setTimeout(() => {
                this.$refs.history.getData(row);
            });
        },
        // 报备信息
        onInformation(row) {
            if (row.productType == 1) {
                this.$router.push({
                    path: '/tmDetail',
                    query: {
                        id: row.id,
                        collaborator: this.btnP.collaborator || false,
                    },
                });
            } else {
                this.$router.push({
                    path: '/aliReportingdetails',
                    query: {
                        id: row.id,
                    },
                });
            }
        },
        handleClose() {
            this.drawerHistory = false;
            this.drawerDetails = false;
        }, // 域名处理
        returnDomain(row) {
            if (row.productType == 1) {
                return row.reportDomain;
            }
            if (row.productType == 2) {
                if (row.nicheDomainBOList && row.nicheDomainBOList.length > 0) {
                    return row.nicheDomainBOList[0].domain;
                } else {
                    return '- -';
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.report {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.item {
    p + p {
        margin-top: 10px;
    }
    span {
        display: inline-block;
        vertical-align: middle;
        width: 70px;
        font-size: 12px;
        text-align: center;
        height: 25px;
        line-height: 25px;
        border-radius: 3px;
        margin-left: 10px;
    }
    .success {
        border: 1px solid #2370eb;
        i {
            color: #2370eb;
        }
    }
    .warning {
        border: 1px solid #d0021b;
        i {
            color: #d0021b;
        }
    }
    .wait {
        border: 1px solid #f5a623;
        i {
            color: #f5a623;
        }
    }
    .faid {
        border: 1px solid #d0021b;
        i {
            color: #d0021b;
        }
    }
}
</style>
