<template>
    <div class="already_box">
        <div class="tm" v-if="tmTableData && tmTableData.length > 0">
            <p class="title">
                <span class="iconfont icon-tengxunqiyeyouxiang"></span>
                腾讯企业邮箱
            </p>
            <el-table
                v-if="tmTableData"
                class="table-box"
                ref="multipleTable"
                :data="tmTableData"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '500',
                }"
            >
                <el-table-column width="20"> </el-table-column>

                <el-table-column label="公司名称" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(scope.row)"
                            v-if="scope.row.companyName"
                            class="iconfont icon-fuzhi1"
                        ></el-button>
                        <span type="text">{{ scope.row.companyName }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="员工"
                    prop="saleAdminName"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    label="开通时间-到期时间"
                    min-width="150"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span
                            v-if="
                                scope.row.reportTime &&
                                scope.row.reportExpireTime
                            "
                        >
                            <span>{{
                                scope.row.reportTime
                                    ? scope.row.reportTime.slice(0, 10)
                                    : ''
                            }}</span>
                            ~
                            <span>{{
                                scope.row.reportExpireTime
                                    ? scope.row.reportExpireTime.slice(0, 10)
                                    : ''
                            }}</span>
                        </span>
                        <span v-else>- -</span>
                    </template>
                </el-table-column>

                <el-table-column label="系统回收时间" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.recoveryTime
                                ? scope.row.recoveryTime.slice(0, 10)
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="domain"
                    label="域名"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.domain ? scope.row.domain : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="账号数"
                    prop="number"
                    show-overflow-tooltip
                    width="80"
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.number ? scope.row.number : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="启用/创建账户"
                    show-overflow-tooltip
                    width="100"
                >
                    <template slot-scope="scope">
                        <span style="color: #2370eb">{{
                            scope.row.enabledNumber
                        }}</span>
                        /
                        <span>{{ scope.row.createNumber }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="VIP账号"
                    prop="vipNumber"
                    show-overflow-tooltip
                    width="80"
                >
                </el-table-column>
                <el-table-column
                    label="下单后台"
                    prop="placeBackstage"
                    show-overflow-tooltip
                    width="100"
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.libraryName
                                ? scope.row.libraryName
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="CorpID"
                    show-overflow-tooltip
                    width="80"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.corpId">
                            <el-popover
                                placement="bottom"
                                trigger="click"
                                :content="scope.row.corpId"
                            >
                                <el-button
                                    slot="reference"
                                    style="color: #2370eb"
                                    type="text"
                                    >查看</el-button
                                >
                            </el-popover>
                        </span>
                        <span v-else>- -</span>
                    </template>
                </el-table-column>
                <el-table-column label="密钥" show-overflow-tooltip width="80">
                    <template slot-scope="scope">
                        <span v-if="scope.row.corpSecret">
                            <el-popover
                                placement="bottom"
                                trigger="click"
                                :content="scope.row.corpSecret"
                            >
                                <el-button
                                    slot="reference"
                                    style="color: #2370eb"
                                    type="text"
                                    >查看</el-button
                                >
                            </el-popover></span
                        >
                        <span v-else>- -</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="90"
                    show-overflow-tooltip
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            style="color: #2370eb"
                            @click="onUpDataTm(scope.row)"
                            >编辑</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="ali" v-if="aliTableData && aliTableData.length > 0">
            <p class="title">
                <span class="iconfont icon-tengxunqiyeyouxiang"></span>
                阿里企业邮箱
            </p>
            <el-table
                class="table-box"
                ref="multipleTable"
                :data="aliTableData"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '500',
                }"
            >
                <el-table-column width="20"> </el-table-column>

                <el-table-column label="公司名称" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(scope.row)"
                            v-if="scope.row.companyName"
                            class="iconfont icon-fuzhi1"
                        ></el-button>
                        <span type="text">{{ scope.row.companyName }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="员工"
                    prop="saleAdminName"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.saleAdminName
                                ? scope.row.saleAdminName
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="开通时间-到期时间"
                    show-overflow-tooltip
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span
                            v-if="
                                scope.row.reportTime &&
                                scope.row.reportExpireTime
                            "
                        >
                            <span>{{
                                scope.row.reportTime
                                    ? scope.row.reportTime.slice(0, 10)
                                    : '- -'
                            }}</span>
                            ~
                            <span>{{
                                scope.row.reportExpireTime
                                    ? scope.row.reportExpireTime.slice(0, 10)
                                    : ''
                            }}</span>
                        </span>
                        <span v-else>- -</span>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="domain"
                    label="域名"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.domain ? scope.row.domain : '- -'
                        }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="管理员账户"
                    prop="adminAccount"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    label="账号数"
                    prop="number"
                    show-overflow-tooltip
                    width="100"
                >
                </el-table-column>
                <el-table-column
                    label="邮箱版本"
                    show-overflow-tooltip
                    width="100"
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.emailVersion == 0
                                ? '标准版'
                                : scope.row.emailVersion == 1
                                ? '尊享版'
                                : scope.row.emailVersion == 2
                                ? '集团版'
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="下单后台"
                    prop="placeBackstage"
                    show-overflow-tooltip
                    width="100"
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.libraryName
                                ? scope.row.libraryName
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    show-overflow-tooltip
                    fixed="right"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            style="color: #2370eb"
                            @click="onUpDataAli(scope.row)"
                            >编辑</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div
            class="domain"
            v-if="domainTableData && domainTableData.length > 0"
        >
            <p class="title">
                <span class="iconfont icon-tengxunqiyeyouxiang"></span>
                域名
            </p>
            <el-table
                class="table-box"
                ref="multipleTable"
                :data="domainTableData"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '500',
                }"
            >
                <el-table-column width="20"> </el-table-column>

                <el-table-column label="公司名称" show-overflow-tooltip>
                    <template slot-scope="scope"
                        ><el-button
                            type="text"
                            style="color: #2370eb"
                            @click.stop="onCopy(scope.row)"
                            v-if="scope.row.companyName"
                            class="iconfont icon-fuzhi1"
                        ></el-button>

                        <span type="text">{{ scope.row.companyName }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="员工"
                    prop="saleAdminName"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.saleAdminName
                                ? scope.row.saleAdminName
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="开通时间-到期时间"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span
                            v-if="
                                scope.row.reportTime &&
                                scope.row.reportExpireTime
                            "
                        >
                            <span>{{
                                scope.row.reportTime
                                    ? scope.row.reportTime.slice(0, 10)
                                    : ''
                            }}</span>
                            ~
                            <span>{{
                                scope.row.reportExpireTime
                                    ? scope.row.reportExpireTime.slice(0, 10)
                                    : ''
                            }}</span></span
                        >
                        <span v-else>- -</span>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="domain"
                    label="域名"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.domain ? scope.row.domain : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="域名所有者"
                    prop="domainHave"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.domainHave ? scope.row.domainHave : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="下单后台" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.libraryName
                                ? scope.row.libraryName
                                : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="90"
                    show-overflow-tooltip
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            style="color: #2370eb"
                            @click="onUpDataDomain(scope.row)"
                            >编辑</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 编辑腾讯 -->
        <el-drawer
            :visible.sync="drawerEditTm"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    编辑客户
                </div>
            </template>
            <EditTm @close="handleClose" ref="editTm" />
        </el-drawer>
        <!-- 编辑阿里 -->
        <el-drawer
            :visible.sync="drawerEditAli"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    编辑客户
                </div>
            </template>
            <EditAli @close="handleClose" ref="editAli" />
        </el-drawer>
        <!-- 编辑域名 -->
        <el-drawer
            :visible.sync="drawerEditDomain"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    编辑客户
                </div>
            </template>
            <EditDomain @close="handleClose" ref="editDomain" />
        </el-drawer>
    </div>
</template>
<script>
import { closedCusList } from '@/api/closedCus/closedCus';
import EditTm from '../../../closedCus/tm/edit.vue';
import EditAli from '../../../closedCus/ali/edit.vue';
import EditDomain from '../../../closedCus/domain/edit.vue';
export default {
    components: {
        EditTm,
        EditAli,
        EditDomain,
    },
    data() {
        return {
            tmTableData: [],
            aliTableData: [],
            domainTableData: [],
            btnP: {},
            drawerEditTm: false,
            drawerEditAli: false,
            drawerEditDomain: false,
            direction: 'rtl',
            data: {},
        };
    },
    methods: {
        getData(obj, btn) {},
        onUpDataTm(row) {
            this.drawerEditTm = true;
            setTimeout(() => {
                this.$refs.editTm.getRelation(row);
                this.$refs.editTm.accountList();
            });
        },
        onUpDataAli(row) {
            this.drawerEditAli = true;
            setTimeout(() => {
                this.$refs.editAli.getRelation(row);
                this.$refs.editAli.accountList();
            });
        },
        onUpDataDomain(row) {
            this.drawerEditDomain = true;
            setTimeout(() => {
                this.$refs.editDomain.getRelation(row);
                this.$refs.editDomain.accountList();
            });
        },
        handleClose() {
            this.drawerEditTm = false;
            this.drawerEditAli = false;
            this.drawerEditDomain = false;
            this.getData(this.data, this.btnP);
        }, // 复制
        onCopy(item) {
            let url = item.companyName;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
    },
};
</script>
<style lang="scss" scoped>
.already_box {
    .title {
        font-size: 14px;
        padding: 20px 0 10px 10px;
        .iconfont {
            color: #2370eb;
        }
    }
}
</style>
