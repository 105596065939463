<template>
    <div class="mail_box">
        <div class="content">
            <p>
                <span class="title">原邮箱品牌</span>
                <span class="cont">{{
                    $tableDataHandle.primaryEmailBrand(data)
                }}</span>
            </p>
            <p>
                <span class="title">到期时间</span>
                <span class="cont">
                    {{ data.customerExpireTime || '- -' }}</span
                >
            </p>
        </div>
        <div class="content">
            <p>
                <span class="title" style="margin-top: 5px">联系备注</span>
                <span class="cont"> {{ data.contactRemark || '- -' }}</span>
            </p>
            <p>
                <span class="title" style="margin-top: 5px">备注</span>
                <span class="cont"> {{ data.remark || '- -' }}</span>
            </p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            data: {},
            disabled: false,
        };
    },
    methods: {
        getData(data) {
            this.data = data;
        },
        // upData() {
        //     this.disabled = true;
        // },
        // cancel() {
        //     this.disabled = false;
        // },
        // onSubmit() {
        //     let obj = {
        //         param: {
        //             customerDO: {
        //                 companyName: this.data.companyName,
        //                 term: this.data.term,
        //                 number: this.data.number,
        //                 contactRemark: this.data.contactRemark,
        //                 remark: this.data.remark,
        //             },
        //         },
        //     };

        //     obj.param.customerDO.id = this.$formDetails.state.data.id;
        //     editCustomer(obj).then((res) => {
        //         if (res.code == 200) {
        //             this.$message.success('修改成功');
        //             this.$emit('againGet');
        //             this.disabled = false;
        //         }
        //     });
        // },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.mail_box {
    width: 100%;
    padding-bottom: 10px;
    .content {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 7px 24px;
        font-size: 13px;
        p:first-child {
            flex: 1;
            display: flex;
            align-items: center;
        }
        p:last-child {
            flex: 2;
            display: flex;
            align-items: center;
        }
        span {
            display: inline-block;
        }
        .title {
            min-width: 65px;
            margin-right: 27px;
            color: #666666;
        }
        .cont {
            color: #333333;
            font-weight: 600;
        }
    }
}
</style>
<style>
.mail_box .el-input.is-disabled .el-input__inner {
    background: #fff !important;
}
.mail_box .el-input__inner {
    padding-left: 10px !important;
}
.mail_box .el-input--small .el-input__inner {
    padding-left: 10px !important;
    padding-right: 0 !important;
}
.mail_box .el-textarea.is-disabled .el-textarea__inner {
    background: #fff !important;
    color: #333;
    font-size: 13px;
}
</style>
