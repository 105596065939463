<template>
    <div class="information">
        <!-- <div class="switch">
            <p>基础信息</p>
            <div class="tab">
                <span :class="index == 1 ? 'isColor' : ''" @click="onSwitch(1, 'basic_box')"
                    >基本资料</span
                >
                <span :class="index == 2 ? 'isColor' : ''" @click="onSwitch(2, 'mail_box')"
                    >邮箱需求</span
                >
                <span :class="index == 3 ? 'isColor' : ''" @click="onSwitch(3, 'website_box')"
                    >网站资料</span
                >
                <span :class="index == 4 ? 'isColor' : ''" @click="onSwitch(4, 'contacts_box')"
                    >联系人</span
                >
            </div> @scroll.passive="getScroll($event)"
        </div> -->
        <div class="content_box" ref="content">
            <div class="basic_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i
                            class="iconfont icon-ziliaoshouce-xianxing"
                            style="color: #2370eb"
                        ></i>
                        基本资料</span
                    >
                    <p>
                        <el-button
                            @click="onRefresh()"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            <i class="el-icon-refresh-right"></i>
                            刷新
                        </el-button>
                        <el-button
                            v-show="basicFold"
                            @click="basicFold = !basicFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!basicFold"
                            @click="basicFold = !basicFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Basic
                            v-show="basicFold"
                            ref="basic"
                            @againGet="againGet"
                        />
                    </el-collapse-transition>
                </div>
            </div>
            <!-- <div class="mail_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i
                            class="iconfont icon-youxiang2"
                            style="color: #2370eb; font-size: 13px"
                        ></i>
                        邮箱需求</span
                    >
                    <p>
                        <el-button
                            type="text"
                            size="small"
                            style="color: #333333"
                            @click="onUpData()"
                            v-show="
                                !dialogMail &&
                                (data.adminId == adminId ||
                                    (searchUserCustomer &&
                                        data.departmentId == departmentId))
                            "
                        >
                            <i
                                class="el-icon-edit-outline"
                                style="color: #2370eb"
                            ></i>
                            编辑</el-button
                        >
                        <el-button
                            v-show="MailFold"
                            @click="MailFold = !MailFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!MailFold"
                            @click="MailFold = !MailFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Mail
                            v-show="MailFold"
                            ref="mail"
                            @againGet="againGet"
                        />
                    </el-collapse-transition>
                </div>
            </div> -->
            <div class="website_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i
                            class="iconfont icon-wangzhan"
                            style="color: #2370eb; font-size: 12px"
                        ></i>
                        网站资料</span
                    >
                    <p>
                        <el-button
                            v-show="
                                data.adminId == adminId ||
                                (searchUserCustomer &&
                                    data.departmentId == departmentId)
                            "
                            @click="onAddWebsite()"
                            type="primary"
                            size="mini"
                            style="
                                margin-right: 12px;
                                color: #2370eb;
                                background: #fff;
                                border-color: #2370eb;
                            "
                        >
                            新增网站资料
                        </el-button>
                        <el-button
                            v-show="websiteFold"
                            @click="websiteFold = !websiteFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!websiteFold"
                            @click="websiteFold = !websiteFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Website
                            v-show="websiteFold"
                            ref="website"
                            @againGet="againGet"
                        />
                    </el-collapse-transition>
                </div>
            </div>
            <div class="contact_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i
                            class="iconfont icon-lianxifangshi"
                            style="color: #2370eb; font-size: 12px"
                        ></i>
                        联系方式</span
                    >
                    <p>
                        <el-button
                            size="mini"
                            style="
                                margin-right: 12px;
                                color: #2370eb;
                                background: #fff;
                                border-color: #2370eb;
                            "
                            @click="onUpDataContact()"
                        >
                            <!-- v-show="
                                !dialogContact &&
                                (data.adminId == adminId ||
                                    (searchUserCustomer &&
                                        data.departmentId == departmentId))
                            " -->

                            新增联系方式</el-button
                        >
                        <el-button
                            v-show="contactFold"
                            @click="contactFold = !contactFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!contactFold"
                            @click="contactFold = !contactFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Contact
                            v-show="contactFold"
                            ref="contact"
                            @againGet="againGet"
                        ></Contact>
                    </el-collapse-transition>
                </div>
            </div>
        </div>
        <!-- 编辑邮箱 -->
        <el-dialog
            :visible.sync="dialogMail"
            width="560px"
            :before-close="onCancel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    邮箱编辑
                </div>
            </template>
            <div>
                <UpdateMail ref="updateMail"></UpdateMail>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="onCancel" round size="mini">取 消</el-button>
                <el-button
                    type="primary"
                    @click="onSubmitMail"
                    round
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
        <!-- 编辑联系方式 -->
        <el-dialog
            :visible.sync="dialogContact"
            width="560px"
            :before-close="onCancel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    联系方式编辑
                </div>
            </template>
            <div>
                <UpdataContact ref="updateContact"></UpdataContact>
            </div>
            <div slot="footer" class="dialog_footer">
                <el-button @click="onCancel" round size="mini">取 消</el-button>
                <el-button
                    type="primary"
                    @click="onSubmitContact"
                    round
                    size="mini"
                    >保 存</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { editCustomer } from '@/api/user/ae/customer';
import Basic from './basic/basic.vue';
import Mail from './mail/mail.vue';
import UpdateMail from './mail/updateMail.vue';
import Website from './website/website.vue';
import Contact from './contact/contact.vue';
import UpdataContact from './contact/updateContact.vue';
export default {
    components: {
        Basic,
        Mail,
        Website,
        UpdateMail,
        Contact,
        UpdataContact,
    },
    data() {
        return {
            index: 1,
            basicFold: true,
            MailFold: true,
            websiteFold: true,
            contactFold: true,

            // contactsFold: true,
            fold: true,
            data: {},
            dialogMail: false,
            dialogContact: false,
            isUpData: false,
            isAdd: false,
            btnP: {},
            adminId: sessionStorage.getItem('adminId'),
            departmentId: sessionStorage.getItem('departmentId'),
            searchUserCustomer:
                Boolean(sessionStorage.getItem('searchUserCustomer')) || false,
        };
    },
    methods: {
        onRefresh() {
            this.$refs.basic.getData(this.data);
        },
        getData(data, btnP) {
            this.btnP = btnP;
            if (this.$route.query.type) {
                this.isUpData = true;
            }
            this.data = data;
            this.dialogMail = false;
            setTimeout(() => {
                this.$refs.basic.getData(data, btnP);
                // this.$refs.mail.getData(data, btnP);
                this.$refs.website.getData(data, btnP);
                if (
                    data.customerMainContactList &&
                    data.customerMainContactList.length > 0
                ) {
                    data.customerMainContactList.forEach((item) => {
                        item.isEdit = false;
                    });
                    this.$refs.contact.getData(
                        data.customerMainContactList.reverse(),
                        btnP
                    );
                }
            });
        },
        onSwitch(i, id) {
            this.index = i;
            this.$el
                .querySelector('.' + id)
                .scrollIntoView({ block: 'start', behavior: 'smooth' });
        },
        againGet() {
            this.dialogMail = false;
            this.$emit('againGet');
        },
        // 邮箱编辑
        onUpData() {
            this.dialogMail = true;
            setTimeout(() => {
                this.$refs.updateMail.getData(this.data);
            }, 0);
        },
        onSubmitMail() {
            let data = {
                param: {
                    customerDO: {
                        ...this.$refs.updateMail.$data.form,
                        id: this.data.id,
                    },
                },
            };
            editCustomer(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.onCancel();
                }
            });
        },
        onCancel() {
            this.dialogMail = false;
            this.dialogContact = false;
            this.$emit('againGet');
        },
        onUpDataContact() {
            setTimeout(() => {
                this.$refs.contact.customerMainContactList.push({
                    contactWay: 9,
                    isEdit: true,
                });
                this.$refs.contact.getData(
                    this.$refs.contact.customerMainContactList,
                    this.btnP
                );
            }, 0);
        },
        onSubmitContact() {
            let data = {
                param: {
                    customerDO: {
                        customerMainContactList:
                            this.$refs.updateContact.$data
                                .customerMainContactList,
                        id: this.data.id,
                    },
                },
            };

            console.log(data);

            editCustomer(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.onCancel();
                }
            });
        },
        // 新增网站
        onAddWebsite() {
            this.$refs.website.onAdd();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.information {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .switch {
        background: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 13px;
        color: #333333;
        padding: 12px 13px 12px 24px;
        .tab {
            span {
                width: 72px;
                height: 20px;
                border-radius: 2px;
                border: 1px solid #e5e5e5;
                font-size: 12px;
                line-height: 20px;
                margin-left: 8px;
                text-align: center;
                display: inline-block;
                cursor: pointer;
            }
            .isColor {
                border: 1px solid #2370eb;
                color: #2370eb;
            }
        }
    }
    .title {
        height: 49px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        font-size: 13px;
        background: #fff;
    }
    .content_box {
        flex: 1;
        overflow: auto;
        margin-top: 8px;
        padding-bottom: 20px;
    }
    .basic_box {
        border-radius: 4px 4px 0 0;
        background: #fff;
    }
    .mail_box {
        margin-top: 8px;
        border-radius: 4px;
        background: #fff;
    }
    .website_box {
        margin-top: 8px;
        border-radius: 4px;
        background: #fff;
    }
    .contact_box {
        margin-top: 8px;
        border-radius: 4px;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
</style>
