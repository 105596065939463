<template>
    <div class="Trial">
        <div class="Trial-box" v-for="item in data" v-if="data.length > 0">
            <el-form
                ref="form"
                style="flex:1; margin-top: 20px;  margin-left: 49px;padding-left: 10px;"
                label-width="100px"
                label-position="left"
            >
                <el-form-item label="申请时间"
                    ><span class="text"> {{ item.aliTrialApplyTime }}</span>
                </el-form-item>
                <el-form-item label="品牌"><span class="text"> 阿里企业邮箱</span></el-form-item>
                <el-form-item label="公司名称"
                    ><span class="text"> {{ item.companyName }} </span>
                </el-form-item>
                <el-form-item label="客户类型"
                    ><span class="text">
                        {{
                            item.emailVersionType == 4
                                ? '阿里-标准版'
                                : item.emailVersionType == 5
                                ? '阿里-尊享版'
                                : item.emailVersionType == 6
                                ? '阿里-集团版'
                                : '- -'
                        }}
                    </span></el-form-item
                >
                <el-form-item label="域名">
                    <span class="text"> {{ item.companyDomain }} </span>
                </el-form-item>
                <el-form-item
                    label="关联域名"
                    v-if="
                        item.customerAliReportDomainList &&
                            item.customerAliReportDomainList.length > 0
                    "
                    v-for="domain in item.customerAliReportDomainList"
                    ><span class="text"> {{ domain.domain }} </span>
                </el-form-item>
                <el-form-item
                    label="关联域名"
                    v-if="
                        !item.customerAliReportDomainList ||
                            item.customerAliReportDomainList.length == 0
                    "
                >
                    <span class="text"> - - </span>
                </el-form-item>
                <el-form-item label="用户数">
                    <span class="text"> {{ item.number }} </span>
                </el-form-item>
                <el-form-item label="试用天数">
                    <span class="text"> {{ item.term }}</span>
                </el-form-item>
                <el-form-item label="备注">
                    <span class="text"> {{ item.remark }} </span>
                </el-form-item>
                <el-form-item label="审核结果">
                    <span class="text">
                        {{
                            item.auditStatus == 1
                                ? '通过'
                                : item.auditStatus == 2
                                ? '驳回'
                                : item.auditStatus == 0
                                ? '申请中'
                                : '- -'
                        }}</span
                    >
                </el-form-item>
                <el-form-item label="审核时间" v-if="item.auditStatus != 0">
                    <span class="text"> {{ item.auditTime }} </span>
                </el-form-item>
                <el-form-item label="到期时间" v-if="item.auditStatus == 1">
                    <span class="text"> {{ item.aliTrialExpireTime }}</span>
                </el-form-item>
                <el-form-item label="账号信息" v-if="item.auditStatus == 1">
                    <span class="text"> {{ item.accountInfo }}</span>
                </el-form-item>
                <el-form-item label="驳回原因" v-if="item.auditStatus == 2">
                    <span class="text"> {{ item.rejectReason }}</span>
                </el-form-item>
            </el-form>
            <div style="padding-bottom:50px">
                <p style="margin-left:60px;font-size:15px;color:#333333">
                    试用历史
                </p>
                <div
                    style="font-size:14px;padding:20px 60px;color:#333333"
                    v-for="item in history"
                    class="list"
                >
                    <span></span>
                    <span>{{ item.createTime }}</span>
                    <p>{{ handle(item) }}</p>
                </div>
                <div class="emptys" v-if="history.length == 0">
                    暂无试用历史数据
                </div>
            </div>
            <div class="but-border" v-if="data.length > 1"></div>
        </div>
        <div class="empty" v-if="data.length == 0">
            暂无试用数据
        </div>
    </div>
</template>
<script>
import { trialList, aliTrialHistoryList } from '@/api/user/ae/ali';
export default {
    data() {
        return {
            total: 0,
            data: [],
            history: [],
        };
    },
    methods: {
        getData(row) {
            let data = {
                param: {
                    customerId: row.id,
                },
                pageNum: 0,
                pageSize: 0,
            };
            trialList(data).then((res) => {
                this.data = res.data.list;
                this.total = res.data.total;
                if (res.data.list.length > 0) {
                    let data = {
                        param: {
                            aliTrialId: res.data.list[0].id,
                        },
                    };
                    aliTrialHistoryList(data).then((res) => {
                        // console.log(res);
                        this.history = res.data.list;
                    });
                }
            });
        },
        handle(item) {
            let str = item.content;
            let text = str
                .replace('%s', item.firstAdminName ? item.firstAdminName : '', 1)
                .replace('%s', item.secondAdminName ? item.secondAdminName : '', 2);
            return text;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.Trial {
    width: 100%;
    overflow: auto;
    .Trial-box {
        height: 100%;
        .text {
            display: inline-block;
            width: 270px;
        }
    }
    .but-border {
        width: 100%;
        border-bottom: 1px dashed #eee;
        margin-bottom: 20px;
    }
    .empty {
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
        color: #ccc;
    }
    .emptys {
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
        color: #ccc;
    }
}
</style>
