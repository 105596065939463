<template>
    <div class="contact_box">
        <el-form label-position="left" size="small" label-width="80px">
            <el-form-item
                :label="index == 0 ? '联系方式' : ''"
                v-for="(item, index) in customerMainContactList"
                :key="index"
            >
                <el-button
                    v-if="index > 0"
                    type="primary"
                    @click="toTop(index)"
                    class="el-icon-top ordinary"
                    circle
                    size="mini"
                ></el-button>
                <el-button
                    v-if="index > 0"
                    type="primary"
                    @click="toTop(index)"
                    class="el-icon-top ordinary"
                    circle
                    size="mini"
                ></el-button>
                <el-select
                    v-model="item.contactWay"
                    size="small"
                    style="width: 75px; margin-left: -50px"
                    placeholder="方式"
                >
                    <el-option label="电话" :value="1"></el-option>
                    <el-option label="微信" :value="2"></el-option>
                    <el-option label="邮箱" :value="3"></el-option>
                    <el-option label="QQ" :value="4"></el-option>
                    <el-option label="短信" :value="5"></el-option>
                    <el-option label="拜访" :value="6"></el-option>
                    <el-option label="座机" :value="9"></el-option>
                    <el-option label="其他" :value="7"></el-option>
                </el-select>
                <el-input
                    style="margin-left: 5px; width: 120px"
                    v-model="item.contactContent"
                    placeholder="请输入内容"
                    size="small"
                ></el-input>
                <el-input
                    style="margin-left: 5px; width: 80px"
                    v-model="item.contactName"
                    placeholder="请输入联系人"
                    size="small"
                ></el-input>
                <el-button
                    v-if="index == 0"
                    @click="addContact"
                    type="primary"
                    class="el-icon-plus"
                    circle
                    size="mini"
                ></el-button>
                <el-button
                    v-if="index > 0"
                    @click="removeContact(index)"
                    class="el-icon-minus"
                    type="primary"
                    circle
                    size="mini"
                ></el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            customerMainContactList: [
                {
                    contactWay: 1,
                },
            ],
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.customerMainContactList = JSON.parse(JSON.stringify(data));
        },
        // 添加联系方式
        addContact() {
            this.customerMainContactList.push({
                contactWay: 1,
            });
        },
        // 删除联系方式
        removeContact(index) {
            this.customerMainContactList.splice(index, 1);
        },
        toTop(index) {
            let arr = this.customerMainContactList.splice(index, 1);
            this.customerMainContactList.unshift(arr[0]);
        },
    },
};
</script>

<style lang="scss" scoped>
.contact_box {
    width: 80%;
    margin: 25px auto 150px;
    text-align: center;
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border: 2px solid #2370eb;
    margin-left: 17px;
}
.el-button--mini.is-circle.ordinary {
    color: #999999;
    border-color: #999999;
    margin-left: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
}
.el-button--mini.is-circle.ordinary:hover {
    background: #fff;
    color: #2370eb;
    border: 2px solid #2370eb;
}
</style>
