var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic"},_vm._l((_vm.contractList),function(item){return _c('div',{staticStyle:{"padding":"20px 0","border-bottom":"1px solid #eeeeee"}},[_c('div',{staticClass:"content_list"},[_c('p',[_c('span',{staticClass:"title"},[_vm._v("合同编号")]),_c('span',{staticClass:"cont"},[_vm._v(_vm._s(item.contractNo ? item.contractNo : '- -'))])]),_c('p',[_c('span',{staticClass:"title"},[_vm._v("甲方")]),_c('span',{staticClass:"cont"},[_vm._v(_vm._s(item.contractA ? item.contractA : '- -'))])]),_c('p',[_c('span',{staticClass:"title"},[_vm._v("打款人")]),_c('span',{staticClass:"cont"},[_vm._v(_vm._s(item.contractA ? item.contractA : '- -'))])])]),_c('div',{staticClass:"content_list"},[_c('p',[_c('span',{staticClass:"title"},[_vm._v("合同金额")]),_c('span',{staticClass:"cont"},[_vm._v(_vm._s(item.contractAmount ? item.contractAmount : '- -'))])]),_c('p',[_c('span',{staticClass:"title"},[_vm._v("乙方")]),(item.typeB != 9)?_c('span',{staticClass:"cont"},[_vm._v(" "+_vm._s(_vm.$tableDataHandle.typeB(item.typeB))+" ")]):_vm._e(),(item.typeB == 9)?_c('span',{staticClass:"cont"},[_vm._v(_vm._s(item.contractB))]):_vm._e()]),_c('p',[_c('span',{staticClass:"title"},[_vm._v("明细")]),_c('span',{staticClass:"cont"},[_vm._v(_vm._s(item.remark ? item.remark : '- -'))])])]),_c('div',{staticClass:"content_list yyzz"},[_c('p',[_c('span',{staticClass:"title",staticStyle:{"display":"inline-block"}},[_vm._v("营业执照")]),(item.businessLicenseUrl)?_c('span',{staticClass:"cont"},[(
                            item.businessLicenseUrl.indexOf('.jpg') != -1 ||
                            item.businessLicenseUrl.indexOf('.png') != -1 ||
                            item.businessLicenseUrl.indexOf('.gif') != -1
                        )?_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":item.businessLicenseUrl,"preview-src-list":[item.businessLicenseUrl]}}):_vm._e(),(
                            item.businessLicenseUrl.indexOf('.pdf') != -1 ||
                            item.businessLicenseUrl.indexOf('.PDF') != -1
                        )?_c('span',{staticClass:"pdf",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(item.businessLicenseUrl)}}},[_c('img',{attrs:{"src":require("@/img/pdfUrl.png"),"alt":""}})]):_vm._e(),(item.businessLicenseUrl.indexOf('.doc') != -1)?_c('span',{staticClass:"word",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(item.businessLicenseUrl)}}},[_c('img',{attrs:{"src":require("@/img/word.png"),"alt":""}})]):_vm._e()],1):_vm._e()]),_c('p',[_c('span',{staticClass:"title",staticStyle:{"display":"inline-block"}},[_vm._v("附件")]),_vm._l((item.contractAttachmentBOList),function(items){return _c('span',{directives:[{name:"show",rawName:"v-show",value:(item.contractAttachmentBOList),expression:"item.contractAttachmentBOList"}],staticClass:"cont",staticStyle:{"margin-right":"8px"}},[(
                            items.attachmentUrl &&
                            (items.attachmentUrl.indexOf('.jpg') != -1 ||
                                items.attachmentUrl.indexOf('.png') != -1 ||
                                items.attachmentUrl.indexOf('.gif') != -1)
                        )?_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":items.attachmentUrl,"preview-src-list":[items.attachmentUrl]}}):_vm._e(),(
                            items.attachmentUrl &&
                            (items.attachmentUrl.indexOf('.pdf') != -1 ||
                                items.attachmentUrl.indexOf('.PDF') != -1)
                        )?_c('span',{staticClass:"pdf",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(items.attachmentUrl)}}},[_c('img',{attrs:{"src":require("@/img/pdfUrl.png"),"alt":""}})]):_vm._e(),(items.attachmentUrl && items.attachmentUrl.indexOf('.doc') != -1)?_c('span',{staticClass:"word",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.onPreview(items.attachmentUrl)}}},[_c('img',{attrs:{"src":require("@/img/word.png"),"alt":""}})]):_vm._e()],1)})],2),_c('p')])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }