<template>
    <div class="details">
        <div
            class="title_box"
            v-if="data != {}"
            :style="{
                background:
                    'url(' + require('@/assets/bg2.png') + ') no-repeat',
                backgroundSize: 'cover',
            }"
        >
            <div class="name_operation">
                <p>
                    <span>
                        <i class="iconfont icon-wodekehu"></i>
                        {{ data.companyName }}
                    </span>
                </p>
                <p>
                    <el-button
                        v-show="data.status != 2"
                        @click="onGiveUp()"
                        size="mini"
                        type="primary"
                        icon="el-icon-delete"
                        style="
                            background: #fff;
                            color: #da5120;
                            border-color: #da5120;
                        "
                        >放弃客户</el-button
                    >
                    <el-button
                        @click="onClose()"
                        size="mini"
                        type="primary"
                        icon="el-icon-arrow-left"
                        style="
                            background: #fff;
                            color: #2370eb;
                            border-color: #2370eb;
                        "
                        >返回列表</el-button
                    >
                    <el-button
                        v-if="isCus == 1"
                        @click="changeCustomer(1)"
                        size="mini"
                        type="primary"
                        :disabled="prevDis"
                        icon="el-icon-caret-left"
                    ></el-button>
                    <el-button
                        v-if="isCus == 1"
                        @click="changeCustomer(2)"
                        size="mini"
                        type="primary"
                        :disabled="nextDis"
                        icon="el-icon-caret-right"
                    ></el-button>
                </p>
            </div>
            <!-- <div class="relation_content">
                <span
                    >联系人 ：
                    <span
                        class="text"
                        v-if="
                            data.customerContactList &&
                            data.customerContactList.length > 0
                        "
                        >{{ data.customerContactList[0].contactName }}</span
                    >
                </span>
                <span
                    >联系方式：
                    <span
                        class="text"
                        v-if="
                            data.customerContactList &&
                            data.customerContactList.length > 0 &&
                            data.customerContactList[0]
                                .customerContactWayDOList &&
                            data.customerContactList[0].customerContactWayDOList
                                .length > 0
                        "
                        >{{
                            data.customerContactList[0]
                                .customerContactWayDOList[0].contactContent
                        }}</span
                    ></span
                >
                <span
                    >负责人 ：
                    <span class="text">{{ data.followAdminName }}</span></span
                >
                <span
                    >最后跟进时间 ：
                    <span
                        class="text"
                        v-if="
                            data.customerIntercourseList &&
                            data.customerIntercourseList.length > 0
                        "
                        >{{
                            data.customerIntercourseList[0].intercourseTime
                        }}</span
                    ></span
                >
            </div> -->
            <div class="label">
                <span style="margin-right: 16px">客户标签</span>
                <span
                    :key="index"
                    :class="item.category == 0 ? 'blue' : 'yellow'"
                    class="labelList"
                    v-for="(item, index) in $choiceLabel.state.labelList"
                    >{{ item.tagName }}
                    <span class="cancel" @click="onDeleteLabel(item)">
                        <span class="el-icon-close"></span>
                    </span>
                </span>
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="small"
                    @click="onChoiceLabel()"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        padding: 5px;
                    "
                ></el-button>
            </div>
        </div>
        <div class="content_box">
            <div class="left_box">
                <div class="tab-box">
                    <p
                        @click="onSwitch(1)"
                        :class="index == 1 ? 'isColor' : ''"
                    >
                        基础信息
                        <span></span>
                    </p>
                    <p
                        @click="onSwitch(2)"
                        :class="index == 2 ? 'isColor' : ''"
                    >
                        交易数据<span></span>
                    </p>
                    <!-- <p
                        @click="onSwitch(3)"
                        :class="index == 3 ? 'isColor' : ''"
                    >
                        报备信息<span></span>
                    </p> -->
                    <p
                        @click="onSwitch(4)"
                        :class="index == 4 ? 'isColor' : ''"
                    >
                        往来<span></span>
                    </p>
                    <p
                        @click="onSwitch(5)"
                        :class="index == 5 ? 'isColor' : ''"
                    >
                        联系人<span></span>
                    </p>
                    <p
                        v-if="customerHistory"
                        @click="onSwitch(6)"
                        :class="index == 6 ? 'isColor' : ''"
                    >
                        客户历史<span></span>
                    </p>
                </div>
                <div class="Information_box" v-show="index == 1">
                    <Information ref="information" @againGet="getData()" />
                </div>
                <div class="trade_box" v-show="index == 2">
                    <Trade ref="trade" />
                </div>
                <div class="report_box" v-show="index == 3">
                    <Report ref="report" />
                </div>
                <div class="report_box" v-show="index == 4">
                    <Dealings ref="dealings" @againGet="getData()" />
                </div>
                <div class="report_box" v-show="index == 5">
                    <Contacts ref="contacts" @againGet="getData" />
                </div>
                <div class="report_box" v-show="index == 6">
                    <CusHistory ref="cusHistory" />
                </div>
            </div>
            <!-- <div class="right_box" v-if="index != 3">
                <Relation ref="relation" @againGet="getData" />
            </div> -->
        </div>
        <el-dialog
            :visible.sync="dialogChoice"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择标签
                </div>
            </template>
            <ChoiceLabel
                @close="handleClose"
                @addLabel="addLabel"
                @addLabel2="addLabel2"
                :isGet="isGet"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogAddlabel"
            width="560px"
            :before-close="handleAddlabel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增个人标签
                </div>
            </template>
            <AddLable
                @close="handleAddlabel"
                :category="1"
                @addLabel="addLabel"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogAddlabel2"
            width="560px"
            :before-close="handleAddlabel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增部门标签
                </div>
            </template>
            <AddLable
                @close="handleAddlabel"
                :category="2"
                @addLabel="addLabel"
            />
        </el-dialog>
        <!-- 放弃客户 -->
        <el-dialog
            :visible.sync="dialogGiveup"
            width="560px"
            :before-close="handleGiveup"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    放弃客户
                </div>
            </template>
            <GiveUp
                ref="giveup"
                @close="handleGiveup"
                @close2="handleGiveup2"
            />
        </el-dialog>
    </div>
</template>
<script>
import Information from '../information/information.vue';
import Trade from '../trade/trade.vue';
import Relation from '../relation/relation.vue';
import { getCustomerId } from '@/api/user/ae/customer';
import { relationList, relationDelete } from '@/api/user/label.js';
import { customerList } from '@/api/customer/customer.js';
import ChoiceLabel from '../../../components/choice-label/choiceLabel.vue';
import AddLable from '../../../components/choice-label/addLabel.vue';
import GiveUp from './giveUp.vue';
import Report from '../report/report.vue';
import Dealings from '../dealings/dealings.vue';
import Contacts from '../contacts/contacts.vue';
import CusHistory from '../cusHistory/cusHistory.vue';
export default {
    components: {
        Information,
        Relation,
        ChoiceLabel,
        AddLable,
        Trade,
        GiveUp,
        Report,
        Dealings,
        Contacts,
        CusHistory,
    },
    data() {
        return {
            data: {},
            type: false,
            index: 1,
            dialogChoice: false,
            dialogAddlabel: false,
            dialogAddlabel2: false,
            isGet: false,
            dialogGiveup: false,
            isUpData: false,
            prevDis: false,
            nextDis: false,
            btnP: {},
            adminId: sessionStorage.getItem('adminId'),
            customerHistory:
                Boolean(sessionStorage.getItem('customerHistory')) || false,
            searchUserCustomer:
                Boolean(sessionStorage.getItem('searchUserCustomer')) || false,
            depSearchCustomer:
                Boolean(sessionStorage.getItem('depSearchCustomer')) || false,
            pageData: JSON.parse(sessionStorage.getItem('pageData')),
            tableData: [],
            totalNub: 0,
            isCus: 0,
        };
    },
    created() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            if (this.$route.query.isCus) {
                this.isCus = this.$route.query.isCus;
            } else {
                this.isCus = 0;
            }

            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }

            this.btnP = btn;

            this.data = {};
            this.getData();
            this.getCustomerList();
            if (this.$route.query.type) {
                this.isUpData = true;
            }
        },
        onSwitch(i) {
            this.index = i;
            if (i == 1) {
                this.$refs.information.getData(this.data, this.btnP);
            } else if (i == 2) {
                setTimeout(() => {
                    this.$refs.trade.getData(this.data, this.btnP);
                });
            } else if (i == 3) {
                this.$refs.report.getData(this.data, this.btnP);
            } else if (i == 4) {
                this.$refs.dealings.getData(this.data, this.btnP);
            } else if (i == 5) {
                this.$refs.contacts.getData(this.data, this.btnP);
            } else if (i == 6) {
                this.$refs.cusHistory.getData(this.data);
            }
        },
        getData() {
            sessionStorage.setItem(
                'cusAdminId',
                this.$formDetails.state.data.adminId
            );
            let obj = {
                param: {
                    id: this.$formDetails.state.data.id,
                },
            };
            getCustomerId(obj).then((res) => {
                this.data = res.data;
                this.getLabelData();

                setTimeout(() => {
                    if (this.index == 1) {
                        this.$refs.information.getData(this.data, this.btnP);
                    } else if (this.index == 2) {
                        this.$refs.trade.getData(this.data, this.btnP);
                    } else if (this.index == 3) {
                        this.$refs.report.getData(this.data, this.btnP);
                    } else if (this.index == 4) {
                        this.$refs.dealings.getData(this.data, this.btnP);
                    } else if (this.index == 5) {
                        this.$refs.contacts.getData(this.data, this.btnP);
                    } else if (this.index == 6) {
                        this.$refs.cusHistory.getData(this.data);
                    }
                });
            });
        },
        // 获取绑定标签
        getLabelData() {
            let data = {
                param: {
                    bandingId: this.data.id,
                    bandingTableType: 2,
                },
            };
            if (this.searchUserCustomer && !this.depSearchCustomer) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                if (!this.depSearchCustomer) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            relationList(data).then((res) => {
                this.$choiceLabel.commit(
                    'getLabelList',
                    JSON.stringify(res.data.list)
                );
            });
        },
        // 新增标签
        addLabel() {
            this.dialogChoice = false;
            this.dialogAddlabel = true;
        },
        // 新增标签
        addLabel2() {
            this.dialogChoice = false;
            this.dialogAddlabel2 = true;
        },
        // 选择标签
        onChoiceLabel() {
            this.dialogChoice = true;
            let that = this;
            setTimeout(() => {
                that.isGet = !that.isGet;
                this.$choiceLabel.commit('isMultiple', false);
                this.$choiceLabel.commit('getType', '2');
                this.$choiceLabel.commit('getBindingType', '2');
                this.$choiceLabel.commit('getIsDetails', true);
            }, 200);
        },
        customerList() {
            return customerList(this.pageData);
        },
        async getCustomerList() {
            let index = Number(sessionStorage.getItem('cusIndex'));
            let res = await this.customerList();
            this.tableData = res.data.list;
            this.totalNub = res.data.total;
            if (index == 0 && this.pageData.pageNum == 1) {
                this.prevDis = true;
            }
            if (
                index == this.tableData.length - 1 &&
                this.pageData.pageNum - 1 ==
                    Math.floor(this.totalNub / this.pageData.pageSize)
            ) {
                this.nextDis = true;
            }
        },
        async changeCustomer(num) {
            let index = Number(sessionStorage.getItem('cusIndex'));
            let res = await this.customerList();
            this.tableData = res.data.list;
            this.totalNub = res.data.total;
            // 上一页切换
            if (num == 1) {
                this.nextDis = false;
                // 第一页并且第一个
                if (index == 0 && this.pageData.pageNum == 1) {
                    this.$message.error('当前客户已经是第一条数据了');
                    this.prevDis = true;
                }
                // 第一页且不是第一个
                if (index > 0 && this.pageData.pageNum == 1) {
                    this.prevDis = false;
                    sessionStorage.setItem('cusIndex', index - 1);
                    this.$formDetails.commit(
                        'detailsData',
                        JSON.stringify(res.data.list[index - 1])
                    );
                }
                // 不是第一页
                if (this.pageData.pageNum > 1) {
                    this.prevDis = false;
                    // 本页第一条
                    if (index == 0) {
                        this.pageData.pageNum -= 1;
                        sessionStorage.setItem(
                            'pageData',
                            JSON.stringify(this.pageData)
                        );
                        let res = await this.customerList();
                        this.tableData = res.data.list;
                        this.totalNub = res.data.total;
                        sessionStorage.setItem(
                            'cusIndex',
                            res.data.list.length - 1
                        );
                        this.$formDetails.commit(
                            'detailsData',
                            JSON.stringify(
                                res.data.list[res.data.list.length - 1]
                            )
                        );
                    } else {
                        // 其他
                        sessionStorage.setItem('cusIndex', index - 1);
                        this.$formDetails.commit(
                            'detailsData',
                            JSON.stringify(res.data.list[index + -1])
                        );
                    }
                }
            }

            // 下一页切换
            if (num == 2) {
                this.prevDis = false;
                // 最后一页并且最后一个
                if (
                    index == this.tableData.length - 1 &&
                    this.pageData.pageNum - 1 ==
                        Math.floor(this.totalNub / this.pageData.pageSize)
                ) {
                    this.$message.error('当前客户已经是最后一条数据了');
                    this.nextDis = true;
                }
                // 最后一页且不是最后一个
                if (
                    index < this.tableData.length - 1 &&
                    this.pageData.pageNum - 1 ==
                        Math.floor(this.totalNub / this.pageData.pageSize)
                ) {
                    this.nextDis = false;
                    sessionStorage.setItem('cusIndex', index + 1);
                    this.$formDetails.commit(
                        'detailsData',
                        JSON.stringify(res.data.list[index + 1])
                    );
                }
                // 不是最后一页
                if (
                    this.pageData.pageNum - 1 <
                    Math.floor(this.totalNub / this.pageData.pageSize)
                ) {
                    this.nextDis = false;
                    // 本页最后一条
                    if (index == res.data.list.length - 1) {
                        this.pageData.pageNum += 1;
                        sessionStorage.setItem(
                            'pageData',
                            JSON.stringify(this.pageData)
                        );
                        let res = await this.customerList();
                        this.tableData = res.data.list;
                        this.totalNub = res.data.total;
                        sessionStorage.setItem('cusIndex', 0);
                        this.$formDetails.commit(
                            'detailsData',
                            JSON.stringify(res.data.list[0])
                        );
                    } else {
                        // 其他
                        sessionStorage.setItem('cusIndex', index + 1);
                        this.$formDetails.commit(
                            'detailsData',
                            JSON.stringify(res.data.list[index + 1])
                        );
                    }
                }
            }

            // customerList(this.pageData).then((res) => {
            //     this.tableData = res.data.list;
            //     this.totalNub = res.data.total;
            //     // // 上一个
            //     // if (num == 1) {
            //     //     if (index == 0 && this.pageData.pageNum == 1) {
            //     //         this.prevDis = true;
            //     //     } else if (index == 0 && this.pageData.pageNum > 1) {
            //     //         this.prevDis = false;
            //     //         sessionStorage.setItem(
            //     //             'cusIndex',
            //     //             this.tableData.length - 1
            //     //         );
            //     //         this.$formDetails.commit(
            //     //             'detailsData',
            //     //             JSON.stringify(this.tableData[index - 1])
            //     //         );
            //     //         this.pageData.pageNum -= 1;
            //     //         sessionStorage.setItem(
            //     //             'pageData',
            //     //             JSON.stringify(this.pageData)
            //     //         );
            //     //     }
            //     // }
            //     // 下一个
            //     // else {

            //     // }
            //     this.getData();
            // });
            this.getData();
        },
        // 移除标签
        onDeleteLabel(item) {
            this.$choiceLabel.commit('removeLabel', JSON.stringify(item));
            let data = {
                param: {
                    id: item.id,
                },
            };
            relationDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                }
            });
        },
        handleClose() {
            this.dialogChoice = false;
            this.getLabelData();
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.dialogAddlabel2 = false;
            this.dialogChoice = true;
            this.isGet = !this.isGet;
        },
        onClose() {
            window.history.go(-1);
            sessionStorage.removeItem('cusIndex');
        },
        // 放弃客户
        onGiveUp() {
            this.dialogGiveup = true;
            setTimeout(() => {
                this.$refs.giveup.getGiveup(this.data);
            }, 50);
        },
        handleGiveup() {
            this.dialogGiveup = false;
            // this.onClose();
        },
        handleGiveup2() {
            this.dialogGiveup = false;
        },
    },
    destroyed() {
        this.$choiceLabel.commit('emptyData');
        window.removeEventListener('scroll', this.handleScroll);
        sessionStorage.removeItem('cusAdminId');
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title_box {
        padding: 16px 20px;
        background: #fff;
        .name_operation {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            p {
                i {
                    color: #2370eb;
                    margin-right: 12px;
                }
                span {
                    font-size: 16px;
                }
            }
            .el-button--primary.is-disabled {
                background-color: #fff;
                border-color: #ebeef5;
                color: #c0c4cc;
            }
            .el-button--primary {
                background-color: #2370eb;
            }
        }
        .relation_content {
            font-size: 14px;
            color: #666;
            padding: 12px 0 14px 0;
            .text {
                color: #333333;
                margin-right: 32px;
            }
        }
        .label {
            font-size: 14px;
        }
    }
    .content_box {
        height: 100%;
        width: 100%;
        flex: 1;
        display: flex;
        margin-top: 16px;
        min-height: 0;
        .left_box {
            height: 100%;
            width: 100%;
            flex: 1;
            min-height: 0;
            display: flex;
            flex-direction: column;
            .tab-box {
                display: flex;
                align-items: center;
                background: #fff;
                height: 44px;
                border-bottom: 1px solid #eee;
                p {
                    cursor: pointer;
                    font-weight: 500;
                    color: #666666;
                    font-size: 14px;
                    padding: 0 16px;
                    height: 100%;
                    line-height: 44px;
                    width: auto;
                }
                .isColor {
                    color: #2370eb;
                    position: relative;
                    span {
                        display: block;
                        width: 16px;
                        height: 3px;
                        background: #2370eb;
                        border-radius: 1px 1px 0px 0px;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        margin-left: -8px;
                    }
                }
            }
            .Information_box {
                flex: 1;
                min-height: 0;
            }
            .trade_box {
                flex: 1;
                min-height: 0;
            }
            .report_box {
                flex: 1;
                min-height: 0;
            }
        }
        .right_box {
            flex: 1;
            margin-left: 16px;
        }
    }
}
.labelList {
    display: inline-block;
    padding: 0 8px;
    height: 24px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    line-height: 24px;
    margin-right: 18px;
    cursor: pointer;
    position: relative;
    .cancel {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -10px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -12px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
}
.blue {
    color: #2370eb;
    border-color: #2370eb;
    .cancel {
        border-left: 10px solid #2370eb;
    }
}
.yellow {
    color: #fa8c15;
    border-color: #fa8c15;
    .cancel {
        border-left: 10px solid #fa8c15;
    }
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    border: 2px solid #2370eb;
    margin-left: 17px;
}
.contact_way_box {
    background-color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 10px;
    position: relative;
}
</style>
