<template>
    <div class="relation">
        <div class="switch">
            <div class="tab">
                <span
                    :class="index == 1 ? 'isColor' : ''"
                    @click="onSwitch(1, 'todo_box')"
                    >我的待办</span
                >
                <span
                    :class="index == 2 ? 'isColor' : ''"
                    @click="onSwitch(2, 'followUp_box')"
                    >跟进计划</span
                >
                <span
                    :class="index == 3 ? 'isColor' : ''"
                    @click="onSwitch(3, 'dealings_box')"
                    >往来记录</span
                >
                <span
                    :class="index == 4 ? 'isColor' : ''"
                    @click="onSwitch(4, 'history_box')"
                    >客户历史</span
                >
            </div>
        </div>
        <div
            class="content_box"
            ref="content"
            @scroll.passive="getScroll($event)"
        >
            <div class="todo_box">
                <div class="title">
                    <span>
                        <i class="shu"></i>
                        我的待办</span
                    >
                    <p>
                        <el-button
                            :class="isBtnColor ? 'isBtnColor' : ''"
                            @click="
                                (isBtnColor = !isBtnColor),
                                    onSwitchData(isBtnColor)
                            "
                            type="primary"
                            size="mini"
                            style="margin-right:12px;color:#2370EB;background:#fff;border-color:#2370EB"
                        >
                            全部代办
                        </el-button>
                        <el-button
                            v-show="isUpData"
                            @click="onAddTodo()"
                            type="primary"
                            size="mini"
                            style="margin-right:12px;color:#2370EB;background:#fff;border-color:#2370EB"
                        >
                            新增待办
                        </el-button>
                        <el-button
                            v-show="todoFold"
                            @click="todoFold = !todoFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!todoFold"
                            @click="todoFold = !todoFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Todo
                            ref="todo"
                            v-show="todoFold"
                            @againGet="againGet"
                        />
                    </el-collapse-transition>
                </div>
            </div>
            <div class="followUp_box">
                <div class="title">
                    <span>
                        <i class="shu"></i>
                        跟进计划</span
                    >
                    <p>
                        <el-button
                            :class="isFollowUpColor ? 'isBtnColor' : ''"
                            @click="
                                (isFollowUpColor = !isFollowUpColor),
                                    onSwitchisFollowUpColor(isFollowUpColor)
                            "
                            type="primary"
                            size="mini"
                            style="margin-right:12px;color:#2370EB;background:#fff;border-color:#2370EB"
                        >
                            全部计划
                        </el-button>
                        <el-button
                            v-show="isUpData"
                            @click="onAddFollowUp"
                            type="primary"
                            size="mini"
                            style="margin-right:12px;color:#2370EB;background:#fff;border-color:#2370EB"
                        >
                            新增计划
                        </el-button>
                        <el-button
                            v-show="followUpFold"
                            @click="followUpFold = !followUpFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!followUpFold"
                            @click="followUpFold = !followUpFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <FollowUp
                            ref="followUp"
                            v-show="followUpFold"
                            @againGet="againGet"
                        />
                    </el-collapse-transition>
                </div>
            </div>
            <div class="dealings_box">
                <div class="title">
                    <span>
                        <i class="shu"></i>
                        往来记录</span
                    >
                    <p>
                        <el-button
                            v-show="isUpData"
                            @click="onAddDealings"
                            type="primary"
                            size="mini"
                            style="margin-right:12px;color:#2370EB;background:#fff;border-color:#2370EB"
                        >
                            新增往来
                        </el-button>
                        <el-button
                            v-show="dealingsFold"
                            @click="dealingsFold = !dealingsFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!dealingsFold"
                            @click="dealingsFold = !dealingsFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <Dealings
                            ref="dealings"
                            v-show="dealingsFold"
                            @againGet="againGet"
                        />
                    </el-collapse-transition>
                </div>
            </div>
            <div class="history_box">
                <div class="title">
                    <span>
                        <i class="shu"></i>
                        客户历史</span
                    >
                    <p>
                        <el-button
                            v-show="historyFold"
                            @click="historyFold = !historyFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!historyFold"
                            @click="historyFold = !historyFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <History ref="history" v-show="historyFold" />
                    </el-collapse-transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Todo from './todo/todo.vue';
import FollowUp from './followUp/followUp.vue';
import Dealings from './dealings/dealings.vue';
import History from './history/history.vue';
export default {
    components: {
        Todo,
        FollowUp,
        Dealings,
        History,
    },
    data() {
        return {
            index: 1,
            todoFold: true,
            followUpFold: true,
            dealingsFold: true,
            historyFold: true,
            fold: true,
            isUpData: false,
            isBtnColor: true,
            isFollowUpColor: true,
            data: {},
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, true);
    },
    methods: {
        getData(data) {
            this.data = data;
            if (this.$route.query.type) {
                this.isUpData = true;
            }
            setTimeout(() => {
                this.$refs.todo.getData(data);
                this.$refs.followUp.getData(data);
                this.$refs.dealings.getData(data);
                this.$refs.history.getData(data);
            });
        },
        getScroll(event) {
            if (event.target.scrollTop < 50) {
                this.index = 1;
            }
        },
        handleScroll() {
            if (
                this.$refs.followUp.$el.getBoundingClientRect().top -
                    this.$refs.content.getBoundingClientRect().top <
                50
            ) {
                this.index = 2;
            }
            if (
                this.$refs.dealings.$el.getBoundingClientRect().top -
                    this.$refs.content.getBoundingClientRect().top <
                50
            ) {
                this.index = 3;
            }
            if (
                this.$refs.history.$el.getBoundingClientRect().top -
                    this.$refs.content.getBoundingClientRect().top <
                50
            ) {
                this.index = 4;
            }
        },
        onSwitch(i, id) {
            this.index = i;
            this.$el
                .querySelector('.' + id)
                .scrollIntoView({ block: 'start', behavior: 'smooth' });
        },
        onSwitchData(is) {
            // console.log(is);
            if (is) {
                this.$refs.todo.getData(this.data);
            } else {
                this.$refs.todo.getData(this.data, 0);
            }
        },
        onSwitchisFollowUpColor(is) {
            if (is) {
                this.$refs.followUp.getData(this.data);
            } else {
                this.$refs.followUp.getData(this.data, 0);
            }
        },
        // 添加待办
        onAddTodo() {
            this.$refs.todo.onAdd();
        },
        // 添加跟进
        onAddFollowUp() {
            this.$refs.followUp.onAdd();
        },
        // 添加往来
        onAddDealings() {
            this.$refs.dealings.onAdd();
        },
        againGet() {
            this.$emit('againGet');
        },
    },
    beforeDestroy: function() {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.relation {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .switch {
        line-height: 44px;
        background: #ffffff;
        border-radius: 4px;
        .tab {
            span {
                width: 72px;
                height: 24px;
                border-radius: 2px;
                border: 1px solid #e5e5e5;
                font-size: 14px;
                line-height: 24px;
                margin-left: 24px;
                text-align: center;
                display: inline-block;
                cursor: pointer;
                font-family: PingFangSC-Regular, PingFang SC;
            }
            .isColor {
                border: 1px solid #2370eb;
                color: #2370eb;
            }
        }
    }
    .content_box {
        position: relative;
        flex: 1;
        margin-top: 8px;
        overflow: auto;
        padding-bottom: 20px;
        div {
            background: #fff;
            border-radius: 4px;
        }
        .title {
            height: 49px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #eeeeee;
            font-size: 13px;
            span {
                display: flex;
                .shu {
                    display: inline-block;
                    width: 2px;
                    height: 16px;
                    background: #2370eb;
                    margin-right: 22px;
                }
            }
        }
        .followUp_box {
            margin-top: 8px;
        }
        .dealings_box {
            margin-top: 8px;
        }
        .history_box {
            margin-top: 8px;
        }
    }
}
.isBtnColor {
    background: #2370eb !important;
    color: #fff !important;
}
</style>
