<template>
    <div class="add-Mail">
        <el-form
            ref="form"
            :model="form"
            style="padding-left: 43px; flex: 1; margin-top: 20px"
            label-width="85px"
            size="small"
            label-position="left"
        >
            <el-form-item label="联系人" required>
                <el-input
                    v-model="form.contactName"
                    placeholder="请输入联系人"
                    size="small"
                    style="width: 270px"
                ></el-input>
            </el-form-item>
            <el-form-item label="联系方式" required>
                <div
                    class="info contact_way_box"
                    v-for="(itm, index) in customerContactWayDOList"
                    :key="index"
                >
                    <el-button
                        v-if="index > 0"
                        type="primary"
                        @click="toTop(index)"
                        class="el-icon-top ordinary"
                        circle
                        size="mini"
                    ></el-button>
                    <el-select
                        v-model="itm.contactWay"
                        size="small"
                        style="width: 90px"
                        clearable
                        placeholder="方式"
                    >
                        <el-option label="电话" :value="1"></el-option>
                        <el-option label="微信" :value="2"></el-option>
                        <el-option label="邮箱" :value="3"></el-option>
                        <el-option label="QQ" :value="4"></el-option>
                        <el-option label="短信" :value="5"></el-option>
                        <el-option label="拜访" :value="6"></el-option>
                        <el-option label="其他" :value="7"></el-option>
                    </el-select>
                    <el-input
                        style="margin-left: 10px; width: 170px"
                        v-model="itm.contactContent"
                        placeholder="请输入联系内容"
                        size="small"
                    ></el-input>
                    <el-button
                        v-if="index == 0"
                        @click="addContact(index)"
                        type="primary"
                        class="el-icon-plus"
                        circle
                        size="mini"
                    ></el-button>
                    <el-button
                        v-if="index > 0"
                        @click.prevent="removeContact(index)"
                        class="el-icon-minus"
                        type="primary"
                        circle
                        size="mini"
                    ></el-button>
                </div>
            </el-form-item>
            <el-form-item label="联系人标签">
                <span
                    :class="item.category == 0 ? 'blue' : 'yellow'"
                    class="labelList"
                    v-for="item in form.contactTagList"
                    >{{ item.tagName }}
                    <span class="cancel" @click="onDeleteLabel(item)">
                        <span class="el-icon-close"></span>
                    </span>
                </span>
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="small"
                    @click="onChoiceLabel"
                    style="
                        background: rgba(0, 0, 0, 0.02);
                        border-color: rgba(0, 0, 0, 0.15);
                        color: #000000;
                        padding: 9px;
                    "
                ></el-button>
            </el-form-item>
            <el-form-item label="性别">
                <el-radio-group v-model="form.sex">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="公司名称">
                <el-input
                    v-model="form.companyName"
                    placeholder="请输入公司名称"
                    size="small"
                    style="width: 270px"
                ></el-input>
            </el-form-item>
            <el-form-item label="部门">
                <el-input
                    size="small"
                    style="width: 270px"
                    v-model="form.department"
                    placeholder="请输入部门"
                ></el-input>
            </el-form-item>
            <el-form-item label="职务">
                <el-input
                    size="small"
                    style="width: 270px"
                    v-model="form.duty"
                    placeholder="请输入职务"
                >
                </el-input>
            </el-form-item>

            <el-form-item label="生日">
                <el-date-picker
                    size="small"
                    style="width: 270px"
                    v-model="form.birthday"
                    type="date"
                    value-format="yyyy-MM-dd hh:mm:ss"
                    placeholder="选择生日"
                >
                </el-date-picker>
            </el-form-item>
            <!-- <p style="font-size: 14px; font-weight: 600; margin-bottom: 16px">
                添加更多信息

                <el-button
                    type="primary"
                    style="
                        background: #fff;
                        border-color: #2370eb;
                        color: #2370eb;
                        padding: 4px 10px;
                        margin-left: 10px;
                    "
                    size="mini"
                    @click="
                        () => {
                            isOpen = !isOpen;
                        }
                    "
                    >{{ isOpen ? '关闭' : '展开' }}</el-button
                >
            </p> -->

            <!-- <div v-show="isOpen">
                <el-form-item label="角色">
                    <el-select
                        v-model="form.role"
                        size="small"
                        style="width: 270px"
                        clearable
                        placeholder="选择角色"
                    >
                        <el-option label="普通人" value="1"></el-option>
                        <el-option label="决策人" value="2"></el-option>
                        <el-option label="使用人" value="3"></el-option>
                        <el-option label="意见影响人" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="昵称">
                    <el-input
                        size="small"
                        style="width: 270px"
                        v-model="form.nickName"
                        placeholder="请输入昵称"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="支付宝">
                    <el-input
                        style="margin-right: 10px; width: 170px"
                        v-model="form.alipayNo"
                        placeholder="请输入支付宝账号"
                        size="small"
                    ></el-input>
                    <el-input
                        size="small"
                        style="width: 90px"
                        v-model="form.alipayName"
                        placeholder="请输入姓名"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="银行信息">
                    <div>
                        <el-input
                            size="small"
                            style="width: 270px; margin-bottom: 6px"
                            v-model="form.bankNo"
                            placeholder="请输入银行卡号"
                        >
                        </el-input>
                    </div>
                    <div>
                        <el-input
                            size="small"
                            style="width: 270px; margin-bottom: 6px"
                            v-model="form.bankDeposit"
                            placeholder="请输入开户行"
                        >
                        </el-input>
                    </div>
                    <div>
                        <el-input
                            size="small"
                            style="width: 270px"
                            v-model="form.bankName"
                            placeholder="请输入姓名"
                        >
                        </el-input>
                    </div>
                </el-form-item>
            </div> -->
        </el-form>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
        <!-- <el-dialog
            :append-to-body="true"
            :visible.sync="dialogChoice"
            width="560px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择标签
                </div>
            </template>
            <ChoiceLabel
                @close="handleClose"
                :isGet="isGet"
                @addLabel="addLabel"
            />
        </el-dialog>
        <el-dialog
            :append-to-body="true"
            :visible.sync="dialogAddlabel"
            width="560px"
            :before-close="handleAddlabel"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增个人标签
                </div>
            </template>
            <AddLable @close="handleAddlabel" @addLabel="addLabel" />
        </el-dialog> -->
    </div>
</template>
<script>
import { contactAdd, contactEdit } from '@/api/user/ae/customer';
import { relationList, relationDelete } from '@/api/user/label.js';
import ChoiceLabel from './choiceLabel.vue';
import AddLable from './addLabel.vue';
export default {
    components: {
        ChoiceLabel,
        AddLable,
    },
    data() {
        return {
            isGet: false,
            form: {},
            dialogChoice: false,
            dialogAddlabel: false,
            customerContactWayDOList: [{}],
            isOpen: true,
            isEdit: false,
        };
    },
    methods: {
        getData(data) {
            this.form = JSON.parse(JSON.stringify(data));
            this.customerContactWayDOList = JSON.parse(
                JSON.stringify(data.customerContactWayDOList)
            );
            this.isEdit = true;
        },

        addContact() {
            this.customerContactWayDOList.push({});
        },
        removeContact(index) {
            this.customerContactWayDOList.splice(index, 1);
        },
        // 置顶
        toTop(index) {
            let arr = this.customerContactWayDOList.splice(index, 1);
            // console.log(arr);
            this.customerContactWayDOList.unshift(arr[0]);
        },
        // 选择标签
        onChoiceLabel() {
            console.log(this.form);
            this.$emit('onChoiceLabel', this.form, this.form.contactTagList);
        },
        handleClose() {
            this.dialogChoice = false;
        },
        // 移除标签
        onDeleteLabel(item) {
            this.$choiceLabel.commit('removeLabel', JSON.stringify(item));
            let data = {
                param: {
                    id: item.id,
                },
            };
            relationDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    let index = this.form.contactTagList.findIndex((itm) => {
                        return itm.id == item.id;
                    });
                    this.form.contactTagList.splice(index, 1);
                }
            });
        },
        // 新增标签
        addLabel() {
            this.dialogChoice = false;
            this.dialogAddlabel = true;
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.dialogChoice = true;
            this.isGet = !this.isGet;
        },
        onSubmit() {
            if (this.form.contactName == '') {
                return this.$message.error('请输入联系人');
            }
            for (let i in this.customerContactWayDOList) {
                if (
                    !this.customerContactWayDOList[i].contactWay ||
                    !this.customerContactWayDOList[i].contactContent
                ) {
                    return this.$message.error('请输入完整联系方式');
                }
            }
            let data = {
                param: {
                    customerContactDO: this.form,
                },
            };

            delete data.param.customerContactDO.contactTagList;

            data.param.companyName = this.$formDetails.state.data.companyName;
            data.param.customerContactDO.followAdminId =
                sessionStorage.getItem('cusAdminId');
            data.param.customerContactDO.customerContactWayDOList =
                this.customerContactWayDOList;
            contactEdit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.index = -1;
                    this.$choiceLabel.commit('emptyData');
                }
                this.onClose();
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add-Mail {
    height: 100%;
    display: flex;
    flex-direction: column;
    .contact_way_box {
        background-color: #fff;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding-left: 0;
        margin-bottom: 10px;
        position: relative;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        background: #fff;
        color: #2370eb;
        font-weight: 600;
        width: 20px;
        height: 20px;
        border: 2px solid #2370eb;
        margin-left: 17px;
    }
    .but {
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 72px;
        }
    }
    .blue {
        color: #2370eb;
        border-color: #2370eb;
        .cancel {
            border-left: 10px solid #2370eb;
        }
    }
    .yellow {
        color: #fa8c15;
        border-color: #fa8c15;
        .cancel {
            border-left: 10px solid #fa8c15;
        }
    }
}
.labelList {
    display: inline-block;
    padding: 0 8px;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    margin-right: 32px;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;

    .cancel {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -10px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -12px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
}
.el-button--mini.is-circle.ordinary {
    color: #999999;
    border-color: #999999;
    margin-left: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
}
.el-button--mini.is-circle.ordinary:hover {
    background: #fff;
    color: #2370eb;
    border: 2px solid #2370eb;
}
.el-form-item {
    margin-bottom: 12px !important;
}
</style>
