<template>
    <div class="clue">
        <div v-for="(form, index) in data">
            <div class="product">
                <p>
                    <span class="title">线索产品</span>
                    <span class="con">{{
                        $tableDataHandle.productCategory(form.productClue)
                    }}</span>
                </p>
                <p>
                    <span class="title">创建时间</span>
                    <span class="con">{{ form.createTime }}</span>
                </p>
                <p style="flex: 3">
                    <span class="title">需求</span>
                    <span class="con">{{ form.remark }}</span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { clueList } from '@/api/user/clue';
export default {
    data() {
        return {
            data: [{}],
            searchUserCustomer:
                Boolean(sessionStorage.getItem('searchUserCustomer')) || false,
            depSearchCustomer:
                Boolean(sessionStorage.getItem('depSearchCustomer')) || false,
        };
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.clue {
    font-size: 13px;
    > div + div {
        border-top: 1px dashed #e5e5e5;
    }
    > div {
        padding: 16px 24px;
    }
    .top {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 8px 0;
    }
    .product {
        display: flex;
        p {
            flex: 1;
        }
    }
    .title {
        min-width: 60px;
        display: inline-block;
        color: #666;
    }
    .con {
        color: #333;
        font-weight: 600;
    }
    .demand {
        padding-top: 16px;
    }
}
</style>
