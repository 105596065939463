<template>
    <div class="teade">
        <!-- <div>
            <div class="title">
                <span style="margin-left: 6px">
                    <i class="iconfont icon-xiansuo1" style="color: #2370eb"></i>
                    已购买产品</span
                >
                <p>
                    <el-button
                        v-show="alreadyFold"
                        @click="alreadyFold = !alreadyFold"
                        type="text"
                        style="margin-right: 12px; color: #2162cb"
                    >
                        收起 <i class="el-icon-arrow-up"></i>
                    </el-button>
                    <el-button
                        v-show="!alreadyFold"
                        @click="alreadyFold = !alreadyFold"
                        type="text"
                        style="margin-right: 12px; color: #2162cb"
                    >
                        展开 <i class="el-icon-arrow-down"></i>
                    </el-button>
                </p>
            </div>
            <div style="background: #fff">
                <Already v-show="alreadyFold" ref="already" />
            </div>
        </div>
        <div class="content_box">
            <div class="clue_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i class="iconfont icon-xiansuo1" style="color: #2370eb"></i>
                        关联线索</span
                    >
                    <p>
                        <el-button
                            v-show="clueFold"
                            @click="clueFold = !clueFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!clueFold"
                            @click="clueFold = !clueFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div style="background: #fff">
                    <Clue v-show="clueFold" ref="clue" />
                </div>
            </div>
            <div class="contract_box">
                <div class="title">
                    <span style="margin-left: 6px">
                        <i
                            class="iconfont icon-gongzuotai"
                            style="color: #2370eb; font-size: 13px"
                        ></i>
                        关联合同</span
                    >
                    <p>
                        <el-button
                            v-show="contractFold"
                            @click="contractFold = !contractFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!contractFold"
                            @click="contractFold = !contractFold"
                            type="text"
                            style="margin-right: 12px; color: #2162cb"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div style="background: #fff">
                    <Contract v-show="contractFold" ref="contract" />
                </div>
            </div>
        </div> -->
        <div class="teade_main">
            <div class="product_box">
                <h2>已购买产品</h2>
                <div>
                    <div
                        :style="{
                            background:
                                'url(' +
                                require('@/img/tmmail.png') +
                                ') no-repeat',
                            backgroundSize: 'cover',
                        }"
                        @click="toAlready('tm', tmProductNum)"
                    >
                        <h2>{{ tmProductNum }}</h2>
                        <p>腾讯邮箱</p>
                    </div>
                    <div
                        :style="{
                            background:
                                'url(' +
                                require('@/img/alimail.png') +
                                ') no-repeat',
                            backgroundSize: 'cover',
                        }"
                        @click="toAlready('ali', aliProductNum)"
                    >
                        <h2>{{ aliProductNum }}</h2>
                        <p>阿里邮箱</p>
                    </div>
                    <div
                        :style="{
                            background:
                                'url(' +
                                require('@/img/wymail.png') +
                                ') no-repeat',
                            backgroundSize: 'cover',
                        }"
                        @click="toAlready('wy', wyProductNum)"
                    >
                        <h2>{{ wyProductNum }}</h2>
                        <p>网易邮箱</p>
                    </div>
                    <div
                        :style="{
                            background:
                                'url(' +
                                require('@/img/domainmail.png') +
                                ') no-repeat',
                            backgroundSize: 'cover',
                        }"
                        @click="toAlready('domain', domainProductNum)"
                    >
                        <h2>{{ domainProductNum }}</h2>
                        <p>域名</p>
                    </div>
                </div>
            </div>
            <div class="relation_clue_box">
                <h2>关联线索</h2>
                <div>
                    <div
                        :style="{
                            background:
                                'url(' +
                                require('@/img/xsline.png') +
                                ') no-repeat',
                            backgroundSize: 'cover',
                        }"
                        @click="toSaleClue"
                    >
                        <h2>{{ saleClueNum }}</h2>
                        <p>销售线索</p>
                    </div>
                    <div
                        :style="{
                            background:
                                'url(' +
                                require('@/img/scline.png') +
                                ') no-repeat',
                            backgroundSize: 'cover',
                        }"
                        @click="toMarketClue"
                    >
                        <h2>{{ marketClueNum }}</h2>
                        <p>市场线索</p>
                    </div>
                </div>
            </div>
            <div class="relation_contract_box">
                <h2>关联合同</h2>
                <div>
                    <div
                        :style="{
                            background:
                                'url(' +
                                require('@/img/glcontr.png') +
                                ') no-repeat',
                            backgroundSize: 'cover',
                        }"
                        @click="toContr"
                    >
                        <h2>{{ contrNum }}</h2>
                        <p>关联合同</p>
                    </div>
                </div>
            </div>
            <div class="relation_contract_box">
                <h2>销售订单</h2>
                <div>
                    <div
                        :style="{
                            background:
                                'url(' +
                                require('@/img/glcontr.png') +
                                ') no-repeat',
                            backgroundSize: 'cover',
                        }"
                        @click="toOrder"
                    >
                        <h2>{{ orderNum }}</h2>
                        <p>销售订单</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Clue from './clue/clue.vue';
import Contract from './contract/contract.vue';
import Report from './report/report.vue';
import Trial from './trial/trial.vue';
import AliReport from './report/aliReport.vue';
import AliTrial from './trial/aliTrial.vue';
import Already from './already/already.vue';
import { closedCusList } from '@/api/closedCus/closedCus';
import { clueList } from '@/api/user/clue';
import { customerId } from '@/api/user/ae/customer';
import { orderList } from '@/api/order/order';
export default {
    components: {
        Clue,
        Contract,
        Report,
        AliReport,
        Trial,
        AliTrial,
        Already,
    },
    data() {
        return {
            index: 1,
            clueFold: true,
            contractFold: true,
            reportFold: true,
            trialFold: true,
            alreadyFold: true,
            isTab: 1,
            tmProductNum: 0,
            aliProductNum: 0,
            domainProductNum: 0,
            wyProductNum: 0,
            saleClueNum: 0,
            marketClueNum: 0,
            contrNum: 0,
            orderNum: 0,
            productType: 0,
            tableRow: {},
            btnP: {},
            searchUserCustomer:
                Boolean(sessionStorage.getItem('searchUserCustomer')) || false,
            depSearchCustomer:
                Boolean(sessionStorage.getItem('depSearchCustomer')) || false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, true);
        console.log(this.$route.query.isTab);
        if (this.$route.query.isTab) {
            this.isTab = this.$route.query.isTab;
        }
    },
    methods: {
        onSwitch(i, id) {
            this.index = i;
            this.$el
                .querySelector('.' + id)
                .scrollIntoView({ block: 'start', behavior: 'smooth' });
        },
        getData(row, btnP) {
            this.tableRow = row;
            this.productNum = 0;
            this.clueNum = 0;
            this.contrNum = 0;
            this.orderNum = 0;
            this.btnP = btnP;
            this.getAlready(row);
            this.getClue(row);
            this.getContract(row);
            this.getOrder(row);
            // setTimeout(() => {
            //     this.$refs.clue.getData(row, btnP);
            //     this.$refs.contract.getData(row, btnP);
            //     this.$refs.already.getData(row, btnP);
            // });
            // if (this.isTab == 1) {
            //     this.$refs.report.getData(row);
            //     this.$refs.trial.getData(row);
            // }
            // if (this.isTab == 2) {
            //     this.$refs.alireport.getData(row);
            //     this.$refs.alitrial.getData(row);
            // }
        },
        getAlready(obj) {
            let data = {
                param: {
                    type: 1,
                    customerId: obj.id,
                },
                pageNum: 0,
                pageSize: 0,
            };
            if (!this.btnP.depSearch && this.btnP.searchUser) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');

                if (this.userVal != '') {
                    data.param.adminId = this.userVal;
                }
            }
            if (this.btnP.depSearch && !this.btnP.searchUser) {
                data.param.departmentId = this.dempVal;
            }
            if (this.btnP.depSearch && this.btnP.searchUser) {
                data.param.departmentId = this.dempVal;
                data.param.adminId = this.userVal;
            }
            if (!this.btnP.depSearch && !this.btnP.searchUser) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            closedCusList(data).then((res) => {
                if (res.code == 200) {
                    this.tmProductNum = res.data.total;
                    // this.productType = 1;
                }
            });
            data.param.type = 2;
            closedCusList(data).then((res) => {
                if (res.code == 200) {
                    this.aliProductNum = res.data.total;

                    // this.productType = 2;
                }
            });
            data.param.type = 12;
            closedCusList(data).then((res) => {
                if (res.code == 200) {
                    this.domainProductNum = res.data.total;

                    // this.productType = 12;
                }
            });
            data.param.type = 4;
            closedCusList(data).then((res) => {
                if (res.code == 200) {
                    this.wyProductNum = res.data.total;
                    // this.productType = 4;
                }
            });
        },
        getClue(row) {
            let data = {
                param: {
                    customerId: row.id,
                },
            };
            if (this.searchUserCustomer && !this.depSearchCustomer) {
                data.param.followDepartmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                if (!this.depSearchCustomer) {
                    data.param.followAdminId =
                        sessionStorage.getItem('adminId');
                }
            }
            data.param.sourceCategory = 2;
            clueList(data).then((res) => {
                this.saleClueNum = res.data.total;
            });
            data.param.sourceCategory = 1;
            clueList(data).then((res) => {
                this.marketClueNum = res.data.total;
            });
        },
        getContract(obj) {
            let data = {
                param: {
                    customerId: obj.id,
                    statusList: [7, 8, 10, 11, 12],
                },
                pageSize: 0,
                pageNum: 0,
            };
            if (this.searchUserCustomer && !this.depSearchCustomer) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                if (!this.depSearchCustomer) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            customerId(data).then((res) => {
                this.contrNum = res.data.total;
            });
        },
        getOrder(obj) {
            let data = {
                param: {
                    customerId: obj.id,
                },
                pageSize: 0,
                pageNum: 0,
            };
            if (this.searchUserCustomer && !this.depSearchCustomer) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                if (!this.depSearchCustomer) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            orderList(data).then((res) => {
                this.orderNum = res.data.total;
            });
        },
        toAlready(type, num) {
            if (num) {
                sessionStorage.setItem(
                    'searchCompanyName',
                    this.tableRow.companyName
                );
                if (type == 'tm') {
                    this.$router.push({
                        path: '/tmmail',
                    });
                }
                if (type == 'ali') {
                    this.$router.push({
                        path: '/alimail',
                    });
                }
                if (type == 'domain') {
                    this.$router.push({
                        path: '/domain',
                    });
                }
                if (type == 'wy') {
                    this.$router.push({
                        path: '/wymail',
                    });
                }
            }
        },
        toSaleClue() {
            if (this.saleClueNum) {
                sessionStorage.setItem(
                    'searchCompanyName',
                    this.tableRow.companyName
                );
                if (this.btnP.searchUser) {
                    this.$router.push({
                        path: '/depSale',
                    });
                } else {
                    this.$router.push({
                        path: '/sale',
                    });
                }
            }
        },
        toMarketClue() {
            if (this.marketClueNum) {
                sessionStorage.setItem(
                    'searchCompanyName',
                    this.tableRow.companyName
                );
                if (this.btnP.searchUser) {
                    this.$router.push({
                        path: '/depMarket',
                    });
                } else {
                    this.$router.push({
                        path: '/market',
                    });
                }
            }
        },
        toContr() {
            if (this.contrNum) {
                sessionStorage.setItem(
                    'searchCompanyName',
                    this.tableRow.companyName
                );
                this.$router.push({
                    path: '/conlist',
                });
            }
        },
        toOrder() {
            if (this.orderNum) {
                sessionStorage.setItem(
                    'searchCompanyName',
                    this.tableRow.companyName
                );
                this.$router.push({
                    path: '/saleOrder',
                });
            }
        },
    },
    beforeDestroy: function () {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.teade {
    width: 100%;
    height: 100%;
    // display: flex;
    // flex-direction: column;
    // overflow: auto;
    box-sizing: border-box;
    padding-top: 12px;
    .teade_main {
        border-radius: 4px;
        display: flex;
        .product_box {
            flex: 2;
        }
        .relation_clue_box {
            // width: 360px;
            flex: 1;
        }
        .relation_contract_box {
            // width: 200px;
            flex: 0.5;
        }
        > div {
            padding: 10px 24px 26px 16px;
            background-color: #fff;
            > h2 {
                font-size: 13px;
                font-weight: normal;
            }
            > div {
                display: flex;
                justify-content: space-between;
                > div {
                    position: relative;
                    cursor: pointer;
                    width: 120px;
                    height: 59px;
                    margin-top: 24px;
                    > p {
                        text-align: center;
                        font-size: 12px;
                        padding-left: 55px;
                        padding-top: 3px;
                    }
                    > h2 {
                        text-align: center;
                        font-size: 18px;
                        font-weight: 600;
                        padding-left: 55px;
                        padding-top: 4px;
                    }
                }
            }
        }
        > div + div {
            margin-left: 12px;
        }
    }
    .switch {
        background: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 13px;
        color: #333333;
        padding: 12px 13px 12px 24px;
        .tab {
            span {
                width: 72px;
                height: 20px;
                border-radius: 2px;
                border: 1px solid #e5e5e5;
                font-size: 12px;
                line-height: 20px;
                margin-left: 8px;
                text-align: center;
                display: inline-block;
                cursor: pointer;
            }
            .isColor {
                border: 1px solid #2370eb;
                color: #2370eb;
            }
        }
    }
    .title {
        height: 49px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        font-size: 13px;
        background: #fff;
    }
    .content_box {
        flex: 1;
        margin-top: 8px;
        padding-bottom: 20px;
    }
    .clue_box {
        height: auto;
        margin-bottom: 10px;
    }
    .contract_box {
        height: auto;
    }
    .report_box {
        width: 49.7%;
        height: auto;
    }
    .trial_box {
        width: 49.7%;
        height: auto;
    }
}
</style>
